import { IonSearchbar } from '@ionic/react';
import React from 'react';
import './SearchBar.scss';

type SearchBarProps = {
  value?: string;
  placeholder?: string;
  onSearchChange?: (e: any) => void;
};

export const filterItems = (
  items: { [index: string]: any }[],
  properties: string[],
  search: string
): object[] => {
  const result: { [key: string]: string }[] = [];
  if (!search) {
    return items;
  }

  items.map((item) => {
    properties.map((propString) => {
      const value = getPropByString(item, propString);
      if (
        !!value &&
        value.toLowerCase().includes(search.toLowerCase()) &&
        !result.includes(item)
      ) {
        result.push(item);
      }
    });
  });
  return result;
};

export const getPropByString = (
  obj: { [index: string]: any },
  propString: string
): string | null => {
  if (!propString) return null;

  let prop;
  const props = propString.split('.');

  for (let i = 0; i < props.length; i++) {
    prop = props[i];

    let candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return typeof obj === 'string' ? obj : null;
};

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  placeholder,
  onSearchChange,
  ...rest
}) => {
  return (
    <IonSearchbar
      className="hk-search-bar"
      value={value}
      placeholder={!!placeholder ? placeholder : 'Search...'}
      onIonChange={(e) =>
        !!onSearchChange ? onSearchChange(e.detail.value!) : null
      }
    ></IonSearchbar>
  );
};

export default SearchBar;
