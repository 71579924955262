import React from 'react';
import Login from '../features/login/Login';
import Checkpoint from '../features/login/Checkpoint';
import Dashboard from '../features/dashboard/Dashboard';

export const firebaseConfig = {
  apiKey: 'AIzaSyBgosQNM1_WbHnXS6-ZgRaVFxgd9ViB90Y',
  authDomain: 'homekeep-e635a.firebaseapp.com',
  projectId: 'homekeep-e635a',
  storageBucket: 'homekeep-e635a.appspot.com',
  messagingSenderId: '961599216636',
  appId: '1:961599216636:web:82acba0bed446166823079',
  measurementId: 'G-N0485QH058'
};

// Environments
export const LOCAL = 'locl';
export const DEVELOPMENT = 'devl';
export const STAGING = 'stag';
export const PRODUCTION = 'prod';

// Numeric Values
export const BASE_REM_VAL = 16;
export const HALF_SECOND = 500;
export const ONE_SECOND = 1000;
export const TWO_SECONDS = 2000;

// Database Type IDs
export const ASSET_TYPE_BATH = 1;
export const ASSET_TYPE_CONTACT = 4;
export const ASSET_TYPE_PAINT = 5;

// Intent
export const PRIMARY = 'primary';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';
export const DARK = 'dark';
export const LIGHT = 'light';
export const NONE = 'none';

// Date Formats
export const DATE_FORMAT_MONTH_DATE = 'MMM d, yyyy';
export const DATE_FORMAT_WEEKDAY_DATE = 'EEEE MMM d, yyyy';
export const DATE_FORMAT_HOUR_MINUTES = 'h:mma';
export const DATE_FORMAT_SHORT_MONTH = 'MMM';
export const DATE_FORMAT_FULL_MONTH_YEAR = 'MMMM yyyy';
export const DATE_FORMAT_DATE_TIMESTAMP = 'yyyy-MM-dd';
export const DATE_FORMAT_DATE_NUM = 'd';
export const DATE_FORMAT_DATE_DAY = 'EEE';

// Strings
export const HEX = 'Hex';
export const PUSH_NOTIFICATIONS_NOTAVAILABLE =
  'PUSH_NOTIFICATIONS_NOTAVAILABLE';
export const PUSH_NOTIFICATIONS_DENIED = 'PUSH_NOTIFICATIONS_DENIED';

// API URLs
// export const HOMEKEEP_URL_SCHEME          = 'homekeep://';
export const HOMEKEEP_URL_MAGIC_CODE = 'https://homekeep.com/auth/mobile?code=';
export const API_HEARTBEAT_URL = '/heartbeat';
export const LOGIN_URL = '/auth/code?email={email}&reset=False';
export const AUTH_URL = '/auth';
export const AUTH_FIREBASE_URL = '/auth/firebase';
export const HOME_URL = '/home/{home_id}';
export const USER_URL = '/user/me';
export const USER_CONTACT_URL = '/user/me/contact';
export const USER_NOTIFICATION_TOKEN_URL = '/user/me/token';
export const APPOINTMENT_LIST_URL =
  '/home/{home_id}/appointments?sort=scheduled&order=desc';
export const APPOINTMENT_DETAILS_URL = '/home/{home_id}/appointment/{appt_id}';
export const APPOINTMENT_TASK_LIST_URL =
  '/home/{home_id}/appointment/{appt_id}/appointment_tasks?sort=sequence';
export const APPOINTMENT_PROPOSALS_URL = '/home/{home_id}/schedule/{hash}';
export const APPOINTMENT_RESCHEDULE_URL =
  '/home/{home_id}/appointment/{appointment_id}/reschedule';
export const UPCOMING_VISITS_URL = '/home/{home_id}/schedule/upcoming';
export const CONTACT_LIST_URL = '/home/{home_id}/contacts';
export const DOCUMENT_COUNT_URL = '/home/{home_id}/documents/count';
export const DOCUMENT_GROUP_URL = '/home/{home_id}/documents/products/{type}';
export const PRODUCT_GROUP_COUNT_URL = '/home/{home_id}/product_group/count';
export const PRODUCT_GROUP_URL = '/home/{home_id}/product_group/{group_id}';
export const PRODUCT_DETAILS_URL = '/home/{home_id}/product/{item_id}';
export const ITEM_REGISTER_URL = '/home/{home_id}/product/{item_id}/register';
export const REGISTER_ALL_URL = '/home/{home_id}/register_all';
export const DEREGISTER_ALL_URL = '/home/{home_id}/deregister_all';
export const ASSET_URL = '/home/{home_id}/asset/{asset_id}';
export const ZENDESK_AUTH_URL = '/auth/zendesk';
export const SIGNUP_URL = 'https://signup.homekeep.com';
export const STATUS_TAG_LIST_URL = '/data/status_tags';
export const ZENDESK_CHAT_URL =
  'https://v2.zopim.com/widget/popout.html?key=Ztfpf39vP3wBg5WsstfJSIFycEQ1tSgi&lang=us';
export const ZENDESK_HELP_URL = 'https://homekeep.zendesk.com/hc/en-us';
// export const ZENDESK_HELP_URL             = '&return_to=https://homekeep.zendesk.com/hc/en-us';
// export const ZENDESK_CHAT_URL             = '&return_to=https://v2.zopim.com/widget/popout.html?key=Ztfpf39vP3wBg5WsstfJSIFycEQ1tSgi&lang=us';

// Regex
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

// Platforms
export enum HKPlatform {
  ANDROID = 'android',
  CAPACITOR = 'capacitor',
  DESKTOP = 'desktop',
  IOS = 'ios'
}

export enum HKEnvironment {
  LOCL = 'locl',
  STAG = 'stag',
  PROD = 'prod'
}

export enum HKVisit {
  Catalog = 'initial_catalog',
  Autumn = 'autumn',
  Spring = 'spring',
  Summer = 'summer',
  Winter = 'winter'
}

export enum HKScheduleType {
  NEW,
  RESCHEDULE,
  COMPLETED
}

export enum HomeAttributes {
  INITIAL_CATALOG = 'initial_catalog'
}

export enum CatalogStatus {
  COMPLETED = 'completed',
  SCHEDULED = 'scheduled',
  NOT_SCHEDULED = 'not_scheduled'
}

export interface State {
  name: string;
  abbreviation: string;
}

export const PAGE_TITLES = [
  { path: '/account', title: 'View Account' },
  { path: '/account/edit/contact', title: 'Edit Account' },
  { path: '/account/edit/home', title: 'Edit Home' },
  { path: '/appointment', title: 'Appointment Details' },
  { path: '/checkpoint', title: 'Checkpoint' },
  { path: '/concierge', title: 'Concierge' },
  { path: '/contacts', title: 'Contacts' },
  { path: '/dashboard', title: 'Dashboard' },
  { path: '/documents', title: 'Documents List' },
  { path: '/documents/group', title: 'Documents Group' },
  { path: '/documents/view', title: 'Document Browser' },
  { path: '/help', title: 'Help' },
  { path: '/initial', title: 'Initial Appointment' },
  { path: '/inventory', title: 'Inventory List' },
  { path: '/inventory/group', title: 'Inventory Group' },
  { path: '/inventory/details', title: 'Inventory Details' },
  { path: '/login', title: 'Login' },
  { path: '/tutorial', title: 'On-boarding' },
  { path: '/wizard', title: 'Appointment Wizard' }
];

export const STATE_LIST: State[] = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' }
];
