import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import { fetchDocumentCount } from './DocumentActions';
import { IonContent, IonIcon, IonPage, IonRow, IonText } from '@ionic/react';
import MenuHeader from '../../core/layout/menu-header/MenuHeader';
import ListItem from '../../components/items/list-item/ListItem';
import SearchBar, { filterItems } from '../../components/searchbar/SearchBar';
import { useHistory } from 'react-router';
import LoadingIonList from '../../core/layout/LoadingIonList';
import { resetDocuments } from './DocumentSlice';
import { HKDocumentType } from './DocumentDetailSlice';
import Manual from '../../assets/icons/manuals.svg';
import Image from '../../assets/icons/image.svg';
import Contracts from '../../assets/icons/contracts.svg';
import { HKPlatform } from 'core/constants';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';
import './DocumentGroupList.scss';
import ArrowRight from '../../assets/icons/arrow-right.svg';

const DocumentGroupList: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const counts = useSelector((state: RootState) => state.documents.counts);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const showGroup = (group: number) => {
    if (group !== undefined) {
      dispatch(resetDocuments());
      history.push(`/documents/group/${group}`);
    }
  };

  const filteredCounts = filterItems(counts || [], ['label'], search);

  const imageForDocumentType = (type: string): string => {
    switch (type) {
      case HKDocumentType.Manuals:
        return Manual;
      case HKDocumentType.Photos:
        return Image;
    }
    return Contracts;
  };

  useEffect(() => {
    if (!counts && !!homeId) {
      dispatch(fetchDocumentCount(homeId));
    }
  }, [dispatch, counts, homeId]);

  function DesktopDocumentsGroupView() {
    return (
      <IonPage>
        <DesktopNavigation>
          <IonRow className="hk-desktop-documents-group-search">
            <SearchBar
              value={search}
              onSearchChange={(e) => setSearch(e)}
            ></SearchBar>
          </IonRow>
        </DesktopNavigation>
        <IonContent className="hk-desktop-documents-group">
          <LoadingIonList
            className="hk-desktop-documents-group-list"
            label="Documents"
            items={counts}
            loading={loading}
          >
            {filteredCounts.map((documentCount: any, i: any) => {
              const { label, count, type } = documentCount;
              return (
                <ListItem
                  key={`item-${i}`}
                  customView={true}
                  onClick={() => showGroup(type)}
                >
                  <IonIcon
                    slot="start"
                    icon={imageForDocumentType(type)}
                    className="hk-desktop-documents-group-list-item-start-icon"
                    color="white"
                  />
                  <div className="hk-desktop-documents-group-list-item-body">
                    <IonText className="hk-desktop-documents-group-list-item-body-title">
                      <h2>{label}</h2>
                    </IonText>
                    <IonText className="hk-desktop-documents-group-list-item-body-description">
                      <p>{count + ' items'}</p>
                    </IonText>
                    <IonIcon
                      slot="end"
                      icon={ArrowRight}
                      className="hk-desktop-documents-group-list-item-body-end-icon"
                    ></IonIcon>
                  </div>
                </ListItem>
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  function MobileDocumentsGroupView() {
    return (
      <IonPage>
        <MenuHeader title={'Documents'} />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList label="Documents" items={counts} loading={loading}>
            {filteredCounts.map((documentCount: any, i: any) => {
              const { label, count, type } = documentCount;
              return (
                <ListItem
                  key={`item-${i}`}
                  startIcon={imageForDocumentType(type)}
                  title={label}
                  description={count + ' items'}
                  onClick={() => showGroup(type)}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsGroupView()
        : MobileDocumentsGroupView()}
    </>
  );
};

export default DocumentGroupList;
