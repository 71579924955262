import React from 'react';
import { IonIcon, IonText, IonThumbnail } from '@ionic/react';
import ArrowRight from '../../../assets/icons/arrow-right.svg';
import Clock from '../../../assets/icons/clock.svg';
import CompletedCheckmark from '../../../assets/icons/completed-check.svg';
import { Appointment } from '../../../core/types';
import './AppointmentCard.scss';
import {
  formatAppointmentDisplayTimeSlot,
  formatTimezoneDate
} from '../../../core/util';
import { DATE_FORMAT_MONTH_DATE, HKPlatform } from '../../../core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

type AppointmentCardProps = {
  icon: string;
  appointment: Appointment;
  timezone: string;
  onClick?: (e: any) => void;
};

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointment,
  timezone,
  icon,
  onClick,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopAppointmentCardView() {
    return (
      <div
        className={`hk-desktop-appointment-card ${
          !!appointment.finished ? 'finished' : ''
        }`}
        onClick={onClick}
        {...rest}
      >
        <div className="hk-desktop-appointment-card-title">
          {icon && (
            <IonIcon
              className="hk-desktop-season-icon"
              color="white"
              icon={icon}
            />
          )}
          {!!appointment.finished ? (
            <div className="hk-desktop-appointment-card-title__name--completed">
              <IonText>Appointment: {appointment?.name}</IonText>
              <div className="hk-complete-pill">complete</div>
            </div>
          ) : (
            <IonText className="hk-desktop-appointment-card-title__name">
              Appointment: {appointment?.name}
            </IonText>
          )}
        </div>
        <div className="hk-desktop-appointment-card-body">
          <IonThumbnail slot="end" className="hk-desktop-list-card-thumbnail">
            <img src={appointment?.worker?.image} alt="Service person" />
          </IonThumbnail>
          <div className="hk-desktop-appointment-card-body-details">
            <div>
              <IonText className="hk-bold">
                {appointment?.worker?.title}
              </IonText>
            </div>
            <div>
              <IonText className="hk-bold">{appointment?.worker?.name}</IonText>
            </div>
          </div>
        </div>
        <div className="hk-desktop-appointment-card-footer">
          <div className="hk-desktop-appointment-card-footer__details">
            <IonText className="hk-desktop-appointment-card-footer__details-title hk-bold">
              Appointment Time
            </IonText>
            <div>
              <span>
                <IonText>
                  {formatTimezoneDate(
                    appointment.scheduled,
                    DATE_FORMAT_MONTH_DATE,
                    timezone
                  )}
                </IonText>
              </span>
              <span>&nbsp;</span>
              <IonText>
                ({formatAppointmentDisplayTimeSlot(appointment, timezone)})
              </IonText>
            </div>
          </div>
          <IonIcon icon={ArrowRight} />
        </div>
      </div>
    );
  }

  function MobileAppointmentCardView() {
    return (
      <div
        className={`hk-appointment-card ion-margin-horizontal ${
          !!appointment.finished ? 'finished' : ''
        }`}
        onClick={onClick}
        {...rest}
      >
        <div className="hk-appointment-card-header-wrapper">
          <div className="hk-appointment-card-header">
            {icon && (
              <IonIcon className="hk-season-icon" color="white" icon={icon} />
            )}
            <IonText className="hk-appointment-card-header-name" color="white">
              {appointment?.name}
            </IonText>
            <IonIcon color="white" icon={ArrowRight} />
          </div>
        </div>
        <div className="hk-appointment-card-body-wrapper">
          <div className="hk-appointment-card-body">
            <IonThumbnail slot="end" className="hk-list-card-thumbnail">
              <img src={appointment?.worker?.image} alt="Service person" />
            </IonThumbnail>
            <div className="hk-appointment-card-body-details">
              <div>
                <IonText color="primary" className="hk-bold">
                  {appointment?.worker?.name}
                </IonText>
              </div>
              <div>
                <IonText color="white">{appointment?.worker?.title}</IonText>
              </div>
            </div>
          </div>
          <div className="hk-appointment-card-footer">
            <span>
              <IonIcon
                color={!!appointment.finished ? 'grey' : 'white'}
                icon={Clock}
              />
              <IonText color={!!appointment.finished ? 'grey' : 'white'}>
                {formatTimezoneDate(
                  appointment.scheduled,
                  DATE_FORMAT_MONTH_DATE,
                  timezone
                )}
              </IonText>
            </span>
            <span>&nbsp;</span>
            {!!appointment.finished ? (
              <span>
                <span>&nbsp;</span>
                <IonIcon color="primary" src={CompletedCheckmark} />
                <IonText color="grey">Completed</IonText>
              </span>
            ) : (
              <IonText
                color={!!appointment.finished ? 'grey' : 'white'}
                className="hk-bold"
              >
                {formatAppointmentDisplayTimeSlot(appointment, timezone)}
              </IonText>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
        <DesktopAppointmentCardView />
      ) : (
        <MobileAppointmentCardView />
      )}
    </>
  );
};

export default AppointmentCard;
