import {
  addMinutes,
  compareAsc,
  format,
  intervalToDuration,
  isValid,
  parseISO
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { InAppBrowserObject } from '@ionic-native/in-app-browser';
import {
  BASE_REM_VAL,
  DATE_FORMAT_HOUR_MINUTES,
  DEVELOPMENT,
  HKEnvironment,
  HKPlatform,
  HKVisit,
  HOMEKEEP_URL_MAGIC_CODE,
  LOCAL,
  PAGE_TITLES,
  PRODUCTION,
  SIGNUP_URL,
  STAGING,
  TWO_SECONDS
} from 'core/constants';
import { Appointment, StatusTag } from '../types';
import Catalog from '../../assets/icons/home-catalog.svg';
import Winter from '../../assets/icons/season-winter.svg';
import Spring from '../../assets/icons/season-spring.svg';
import Summer from '../../assets/icons/season-summer.svg';
import Autumn from '../../assets/icons/season-autumn.svg';

export const gaEvent = (name: string, params?: object) => {
  FirebaseAnalytics.logEvent({
    name: name,
    params: params ? params : {}
  }).then();
};

export const gaScreenView = (path: string) => {
  // filter out root path (/)
  if (path.length > 1) {
    // remove any ids from the end of paths eg: /appointment/:id or /inventory/group/:id
    const stripped = path.replace(/\/\d+/g, '');
    const title = PAGE_TITLES.find((pt) => pt.path === stripped)?.title;
    try {
      FirebaseAnalytics.setScreenName({
        screenName: !!title ? title : stripped,
        nameOverride: !!title ? title : stripped
      }).then();
    } catch (e) {
      console.log('FirebaseAnalytics not initialized');
      // FirebaseAnalytics.initializeFirebase({
      //   apiKey: "AIzaSyDrPfZT5kbJ1pAjRK6tAWn1C53TwLsRdyw",
      //   authDomain: "...",
      //   databaseURL: "...",
      //   projectId: "...",
      //   storageBucket: "homekeep-e635a.appspot.com",
      //   messagingSenderId: "961599216636",
      //   appId: "com.avidratings.homekeep",
      //   measurementId: "homekeep-e635a",
      // });
    }
  }
};

export const copyJSON = (element: object): object => {
  return JSON.parse(JSON.stringify(element));
};

export const remToPx = (rem: string): number => {
  let n = 0;
  if (rem && typeof rem === 'string') {
    n = Number(rem.replace('rem', '')) * BASE_REM_VAL;
  }
  return n;
};

export const replaceParams = (
  route: string,
  params: Array<Array<any>> | Array<any>
): string => {
  let newRoute = route;
  const replace = (p: Array<any>) => {
    newRoute = newRoute.replace(`{${p[0]}}`, String(p[1]));
  };
  if (Array.isArray(params[0])) {
    params.forEach((p) => replace(p));
  } else {
    replace(params);
  }
  return newRoute;
};

export const getEnvName = () => process.env.REACT_APP_ENV_NAME;

export const getBaseApiUrl = () => {
  const { REACT_APP_API_PROTOCOL, REACT_APP_API_URL, REACT_APP_API_PORT } =
    process.env;
  return (
    (REACT_APP_API_PROTOCOL || 'https') +
    '://' +
    REACT_APP_API_URL +
    (REACT_APP_API_PORT ? `:${REACT_APP_API_PORT}` : '')
  );
};

export const apiMinorVersionGreaterThanExpected = (
  apiHeartbeatVersion: string
): boolean => {
  if (
    !!process.env.REACT_APP_API_VERSION &&
    (getEnvName() === HKEnvironment.PROD || getEnvName() === HKEnvironment.STAG)
  ) {
    const expectedMinor = Number(
      process.env.REACT_APP_API_VERSION?.split('.')[1]
    );
    const heartbeatMinor = Number(apiHeartbeatVersion?.split('.')[1]);
    return heartbeatMinor > expectedMinor;
  }
  return false;
};

export const openSignup = (platformType: HKPlatform) => {
  const browser = new InAppBrowserObject(
    SIGNUP_URL,
    '_blank',
    getBrowserOptions(platformType)
  );
  gaScreenView('/signup');
};

// export const openConcierge = (url: string, platformType: HKPlatform) => {
//   const browser = new InAppBrowserObject(ZENDESK_CHAT_URL, '_blank', getBrowserOptions(platformType));
//   gaScreenView('/concierge');
// }

// export const openHelp = (platformType: HKPlatform) => {
//   const browser = new InAppBrowserObject(ZENDESK_HELP_URL, '_blank', getBrowserOptions(platformType));
//   gaScreenView('/help');
// }

export const viewDocument = (url: string, platformType: HKPlatform) => {
  const browser = new InAppBrowserObject(
    url,
    platformType === HKPlatform.ANDROID ? '_system' : '_blank',
    getBrowserOptions(platformType)
  );
  gaScreenView('/document/view');
};

export const openConcierge = (
  zendeskTokenURL: string,
  platformType: HKPlatform
) => {
  const browser = new InAppBrowserObject(
    zendeskTokenURL,
    '_blank',
    getBrowserOptions(platformType)
  );
  // ZENDESK_CHAT_URL
};

export const openHelp = (zendeskTokenURL: string, platformType: HKPlatform) => {
  const browser = new InAppBrowserObject(
    zendeskTokenURL,
    '_blank',
    getBrowserOptions(platformType)
  );
};

export const getBrowserOptions = (platformType: HKPlatform) => {
  let options =
    'location=no,toolbarcolor=#353A4D,closebuttoncolor=#ffffff,hidenavigationbuttons=yes';
  switch (platformType) {
    case HKPlatform.ANDROID:
      options += ',fullscreen=yes';
      break;
    case HKPlatform.IOS:
      options +=
        ',toolbarposition=top,closebuttoncaption=Close,lefttoright=yes';
      break;
  }
  return options;
};

export const validateOpenedURL = (url: string) => {
  if (url.indexOf(HOMEKEEP_URL_MAGIC_CODE) === 0) {
    return `/auth/${url.replace(HOMEKEEP_URL_MAGIC_CODE, '')}`;
  }
  return null;
};

export const highestStatusTagLevel = (tags?: StatusTag[]): number => {
  if (!!tags && tags.length > 0) {
    const sorted = copyJSON(tags) as StatusTag[];
    sorted.sort((a, b) => {
      return b.level - a.level;
    });
    return sorted[0].level;
  }
  return 0;
};

export const isIOS = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.IOS;
};

export const isAndroid = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.ANDROID;
};

export const isDesktop = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.DESKTOP;
};

export const determinePlatformType = (platforms: string[]): HKPlatform => {
  if (
    platforms.includes(HKPlatform.IOS) &&
    platforms.includes(HKPlatform.CAPACITOR)
  ) {
    return HKPlatform.IOS;
  } else if (
    platforms.includes(HKPlatform.ANDROID) &&
    platforms.includes(HKPlatform.CAPACITOR)
  ) {
    return HKPlatform.ANDROID;
  }
  return HKPlatform.DESKTOP;
};

export const isDev = () => {
  const env = getEnvName();
  return env === LOCAL || env === DEVELOPMENT;
};

export const isProd = () => {
  const env = getEnvName();
  return env === STAGING || env === PRODUCTION;
};

export const isFunction = (f: any) => f && typeof f === 'function';

export const sleep = (t = TWO_SECONDS) =>
  new Promise((resolve) => setTimeout(resolve, t));

export const constructAddress = (city: string, state: string, zip: string) => {
  let address = '';

  if (!!city) {
    address += city + ', ';
  }
  if (!!state) {
    address += state + ' ';
  }
  if (!!zip) {
    address += zip;
  }

  return address;
};

export const getVisitIcon = (type: string): string => {
  switch (type) {
    case HKVisit.Catalog:
      return Catalog;
    case HKVisit.Autumn:
      return Autumn;
    case HKVisit.Spring:
      return Spring;
    case HKVisit.Summer:
      return Summer;
    case HKVisit.Winter:
      return Winter;
  }
  return Summer;
};

enum AppointmentType {
  Upcoming,
  Past
}

export const filterAppointments = (
  type: AppointmentType,
  appointments: Appointment[]
): Appointment[] => {
  const now = new Date();
  return appointments.filter((appt) => {
    const apptDate = new Date(appt.scheduled || 0);
    return (
      (type === AppointmentType.Upcoming && compareAsc(apptDate, now) === 1) ||
      (type === AppointmentType.Past && compareAsc(now, apptDate) === 1)
    );
  });
};

export const formatAppointmentDisplayTimeSlot = (
  appointment: Appointment,
  timezone: string
): string => {
  const date = utcToZonedTime(appointment.scheduled, timezone);
  const endtime = addMinutes(date, appointment.duration);
  return `${format(date, DATE_FORMAT_HOUR_MINUTES)} - ${format(
    endtime,
    DATE_FORMAT_HOUR_MINUTES
  )}`;
};

export const formatTaskFinishedDate = (
  date_finished: string,
  timezone: string
): string => {
  const duration = intervalToDuration({
    start: utcToZonedTime(date_finished, timezone || ''),
    end: utcToZonedTime(new Date(), timezone || '')
  });
  if (!!duration) {
    if (!!duration.years && duration.years > 0) {
      return `${duration.years} years ago`;
    }
    if (!!duration.months && duration.months > 0) {
      return `${duration.months} months ago`;
    }
    if (!!duration.weeks && duration.weeks > 0) {
      return `${duration.weeks} weeks ago`;
    }
    if (!!duration.days && duration.days > 0) {
      return `${duration.days} days ago`;
    }
    if (!!duration.hours && duration.hours > 0) {
      return `${duration.hours} hours ago`;
    }
    if (!!duration.minutes && duration.minutes > 0) {
      return `${duration.minutes} minutes ago`;
    }
  }
  return '';
};

export const formatISODate = (date: string, pattern: string): string => {
  return isValid(parseISO(date)) ? format(parseISO(date), pattern) : '';
};

export const formatTimezoneDate = (
  date: string,
  pattern: string,
  timezone?: string
): string => {
  const tzDate = utcToZonedTime(date, timezone || '');
  return `${format(tzDate, pattern)}`;
};
