import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Illustration from '../../components/Illustrations/Illustration';
import UpdateIllustration from '../../assets/illustrations/update.svg';
import './Update.scss';

const Update: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className="hk-update ion-padding" scroll-y="false">
        <section className="ion-text-center ion-margin-vertical">
          <Illustration image={UpdateIllustration} description="HomeKeep" />
        </section>
        <h3 className="ion-text-center">
          <b>Update Available</b>
        </h3>
        <p className="ion-padding-horizontal ion-text-center">
          A new version of HomeKeep is available. Please install the latest
          version to continue using this product.
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Update;
