import { createApiRequest } from 'core/util';
import { USER_CONTACT_URL, USER_URL, ZENDESK_AUTH_URL } from 'core/constants';
import { AppThunk, AppDispatch } from '../store';
import {
  fetchUserFailure,
  fetchZendeskTokenFailure,
  fetchZendeskTokenSuccess,
  setUserInfo,
  updateUserFailure,
  updateUserSuccess
} from './UserSlice';
import { setHomes } from './HomeSlice';

export const requestUserInfo = () =>
  createApiRequest({
    route: USER_URL,
    onSuccess: fetchUserSuccess,
    onError: fetchUserFailure,
    defaultErrorMessage: 'There was a problem retrieving your account.'
  })();

export const fetchUserSuccess =
  (me: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    // console.log(`me: ${JSON.stringify(me)}`);
    dispatch(setUserInfo(me.contact));
    dispatch(setHomes(me.homes));
  };

export const updateUser = (user: object) =>
  createApiRequest({
    route: USER_CONTACT_URL,
    method: 'patch',
    body: user,
    onSuccess: updateUserSuccess,
    onError: updateUserFailure,
    defaultErrorMessage: 'There was a problem updating your account.',
    useGlobalLoader: true
  })();

export const fetchZendeskToken = () =>
  createApiRequest({
    route: ZENDESK_AUTH_URL,
    onSuccess: fetchZendeskTokenSuccess,
    onError: fetchZendeskTokenFailure,
    defaultErrorMessage: 'There was a problem fetching your help token.'
  })();
