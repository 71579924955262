import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactInfo, ContactList } from 'core/types';

interface ContactListState {
  error: string | null;
  concierges: ContactInfo[];
  contractors: ContactInfo[];
  manufacturers: ContactInfo[];
}

export const initialState: ContactListState = {
  error: null,
  concierges: [],
  contractors: [],
  manufacturers: []
};

export const contactListSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    fetchContactListSuccess: (state, action: PayloadAction<ContactList>) => {
      const { concierges, contractors, manufacturers } = action.payload;
      state.concierges = concierges;
      state.concierges.forEach((c) => (c.category = 'Concierge'));
      state.contractors = contractors;
      state.contractors.forEach((c) => (c.category = 'Contractor'));
      state.manufacturers = manufacturers;
      state.manufacturers.forEach((c) => (c.category = 'Manufacturer'));
    },
    fetchContactListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    }
  }
});

export const { fetchContactListSuccess, fetchContactListError } =
  contactListSlice.actions;

export default contactListSlice.reducer;
