import { createApiRequest } from 'core/util';
import {
  fetchDocumentCountError,
  fetchDocumentCountSuccess,
  fetchDocumentsError,
  fetchDocumentsSuccess
} from './DocumentSlice';
import {
  ASSET_URL,
  DOCUMENT_COUNT_URL,
  DOCUMENT_GROUP_URL
} from 'core/constants';
import {
  fetchDocumentDetailsError,
  fetchDocumentDetailsSuccess
} from './DocumentDetailSlice';

export const fetchDocumentCount = (homeId: number) =>
  createApiRequest({
    route: DOCUMENT_COUNT_URL,
    replace: ['home_id', homeId],
    onSuccess: fetchDocumentCountSuccess,
    onError: fetchDocumentCountError,
    defaultErrorMessage: 'There was a problem loading your documents.',
    useGlobalLoader: true
  })();

export const fetchDocuments = (homeId: number, type: string) =>
  createApiRequest({
    route: DOCUMENT_GROUP_URL,
    replace: [
      ['home_id', homeId],
      ['type', type]
    ],
    onSuccess: fetchDocumentsSuccess,
    onError: fetchDocumentsError,
    defaultErrorMessage: 'There was a problem loading your documents.',
    useGlobalLoader: true
  })();

export const requestDocumentDetails = (homeId: number, assetId: number) =>
  createApiRequest({
    route: ASSET_URL,
    replace: [
      ['home_id', homeId],
      ['asset_id', assetId]
    ],
    onSuccess: fetchDocumentDetailsSuccess,
    onError: fetchDocumentDetailsError,
    defaultErrorMessage: 'There was a problem loading document details.',
    useGlobalLoader: true
  })();
