import React from 'react';
import { RootState } from '../../store';
import { menuController } from '@ionic/core';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { hideSlideOutMenu } from '../../services/MenuSlice';
import { openHelp } from '../../util';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenu
} from '@ionic/react';
import Illustration from '../../../components/Illustrations/Illustration';
import LogoIllustration from '../../../assets/illustrations/hk-logo-light.svg';
import HomeIcon from '../../../assets/icons/home.svg';
import InventoryIcon from '../../../assets/icons/inventory.svg';
import ContactsIcon from '../../../assets/icons/contacts.svg';
import DocumentsIcon from '../../../assets/icons/documents.svg';
import './SideMenu.scss';
import { fetchZendeskToken } from 'core/services/UserActions';

interface Page {
  title: string;
  path: string;
  icon: string;
}

const pages: Page[] = [
  { title: 'Dashboard', path: '/dashboard', icon: String(HomeIcon) },
  // { title: 'Concierge', path: '/initial', icon: String(ConceirgeIcon) },
  { title: 'Contacts', path: '/contacts', icon: String(ContactsIcon) },
  { title: 'Inventory', path: '/inventory', icon: String(InventoryIcon) },
  { title: 'Documents', path: '/documents', icon: String(DocumentsIcon) }
];

const SideMenu: React.FC = ({ children }) => {
  const { platformType, version } = useSelector(
    (state: RootState) => state.platform
  );
  const { isOpen } = useSelector((state: RootState) => state.menu);
  const { zendesk_token_url } = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  if (isOpen) {
    openMenu().then(() => {});
  } else {
    closeMenu().then(() => {});
  }

  async function openMenu() {
    await menuController.open();
  }

  async function closeMenu() {
    await menuController.close();
  }

  const closeMenuAndShowHelp = () => {
    dispatch(hideSlideOutMenu());
    dispatch(fetchZendeskToken());
    if (!!zendesk_token_url) {
      openHelp(zendesk_token_url, platformType);
    }
  };

  const navigateTo = (route: string) => {
    history.push(route);
    dispatch(hideSlideOutMenu());
  };

  const menuDidClose = () => {
    dispatch(hideSlideOutMenu());
  };

  return (
    <IonMenu
      side="start"
      type="push"
      contentId="main-content"
      className="main-menu"
      onIonDidClose={() => menuDidClose()}
    >
      <IonHeader scroll-y="false">
        <Illustration image={LogoIllustration} />
      </IonHeader>
      <IonContent>
        <IonList>
          {pages.map((page, i) => {
            return (
              <IonItem
                key={`menu-item-${i}`}
                color={location.pathname.includes(page.path) ? 'grey' : 'dark'}
                onClick={() => navigateTo(page.path)}
              >
                <IonIcon icon={page.icon} slot="start" />
                {page.title}
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonList>
          {/*{!!zendesk_token_url*/}
          {/*  ? <IonItem color="dark" onClick={closeMenuAndShowHelp}>Help</IonItem>*/}
          {/*  : <IonItem color="dark" />*/}
          {/*}*/}
          <IonItem color="dark" onClick={closeMenuAndShowHelp}>
            Help
          </IonItem>
          <IonItem color="dark" />
          <IonItem color="dark" className="no-bold">
            {version}
          </IonItem>
        </IonList>
      </IonFooter>
    </IonMenu>
  );
};

export default SideMenu;
