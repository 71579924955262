import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { requestProductGroupCount } from './InventoryActions';
import { IonContent, IonIcon, IonPage, IonRow, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import SearchBar, { filterItems } from '../../components/searchbar/SearchBar';
import MenuHeader from '../../core/layout/menu-header/MenuHeader';
import ListItem from '../../components/items/list-item/ListItem';
import LoadingIonList from '../../core/layout/LoadingIonList';
import Appliances from '../../assets/icons/appliances.svg';
import Exterior from '../../assets/icons/exterior.svg';
import HotCold from '../../assets/icons/hotcold.svg';
import Plumbing from '../../assets/icons/plumbing.svg';
import Utility from '../../assets/icons/utility.svg';
import { resetProductGroup } from './InventorySlice';
import { HKPlatform } from 'core/constants';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import './InventoryGroupList.scss';

enum ProductGroups {
  Appliances = 1,
  Exterior,
  HeatingCooling,
  Plumbing,
  Utility
}

const InventoryGroupList: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const {
    productGroups,
    product_count,
    registered_product_count,
    allItemsRegistered
  } = useSelector((state: RootState) => state.inventory);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const showGroup = (id: number) => {
    if (id !== undefined) {
      dispatch(resetProductGroup());
      history.push(`/inventory/group/${id}`);
    }
  };

  const filteredInventory = filterItems(productGroups || [], ['group'], search);

  const iconForGroup = (id: number): string => {
    switch (id) {
      case ProductGroups.Appliances:
        return Appliances;
      case ProductGroups.Exterior:
        return Exterior;
      case ProductGroups.HeatingCooling:
        return HotCold;
      case ProductGroups.Plumbing:
        return Plumbing;
      case ProductGroups.Utility:
        return Utility;
    }
    return '';
  };

  useEffect(() => {
    if (!productGroups && !!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
  }, [dispatch, productGroups, homeId]);

  function DesktopInventoryGroupListView() {
    return (
      <IonPage>
        <DesktopNavigation>
          <IonRow className="hk-desktop-inventory-group-search">
            <SearchBar
              value={search}
              onSearchChange={(e) => setSearch(e)}
            ></SearchBar>
          </IonRow>
        </DesktopNavigation>
        <IonContent className="hk-desktop-inventory-group">
          <LoadingIonList
            className="hk-desktop-inventory-group-list"
            label="Inventory"
            items={productGroups}
            loading={loading}
          >
            {filteredInventory.map((g: any, i: any) => {
              const { id, group, count } = g;
              return (
                <ListItem
                  key={`item-${i}`}
                  customView={true}
                  onClick={() => showGroup(id)}
                >
                  <IonIcon
                    slot="start"
                    icon={iconForGroup(id)}
                    className="hk-desktop-inventory-group-list-item-start-icon"
                    color="white"
                  />
                  <div className="hk-desktop-inventory-group-list-item-body">
                    <IonText className="hk-desktop-inventory-group-list-item-body-title">
                      <h2>{group}</h2>
                    </IonText>
                    <IonText className="hk-desktop-inventory-group-list-item-body-description">
                      <p>{count + ' items'}</p>
                    </IonText>
                    <IonIcon
                      slot="end"
                      icon={ArrowRight}
                      className="hk-desktop-inventory-group-list-item-body-end-icon"
                    ></IonIcon>
                  </div>
                </ListItem>
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  function MobileInventoryGroupListView() {
    return (
      <IonPage>
        <MenuHeader title={'Inventory'} />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            label="Inventory"
            items={productGroups}
            loading={loading}
          >
            {filteredInventory.map((g: any, i: any) => {
              const { id, group, count } = g;
              return (
                <ListItem
                  key={`item-${i}`}
                  startIcon={iconForGroup(id)}
                  title={group}
                  description={count + ' items'}
                  onClick={() => showGroup(id)}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInventoryGroupListView()
        : MobileInventoryGroupListView()}
    </>
  );
};

export default InventoryGroupList;
