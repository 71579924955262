import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SUCCESS, DANGER, NONE } from 'core/constants';

interface InitialState {
  loading: boolean;
  intent: string;
  value: number | undefined;
  visible: boolean;
}

export const initialState: InitialState = {
  loading: false,
  intent: '',
  value: undefined,
  visible: false
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setLoadingIntent: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case SUCCESS:
          state.intent = 'SUCCESS';
          break;
        case DANGER:
          state.intent = 'DANGER';
          break;
        case NONE:
        default:
          state.intent = 'NONE';
      }
    },
    setLoadingValue: (state, action: PayloadAction<number | undefined>) => {
      state.value = action.payload;
    }
  }
});

export const { setLoading, setVisible, setLoadingIntent, setLoadingValue } =
  loadingSlice.actions;

export default loadingSlice.reducer;
