import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { requestContactList } from './ContactListActions';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import { callOutline } from 'ionicons/icons';
import MenuHeader from '../../core/layout/menu-header/MenuHeader';
import ListItem from 'components/items/list-item/ListItem';
import SearchBar, { filterItems } from '../../components/searchbar/SearchBar';
import LoadingIonList from '../../core/layout/LoadingIonList';
import './ContactList.scss';
import { HKPlatform } from 'core/constants';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';

const ContactsList: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { loading } = useSelector((state: RootState) => state.loading);
  const { contractors } = useSelector((state: RootState) => state.contacts);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const filteredContacts = filterItems(
    contractors || [],
    ['name', 'category'],
    search
  );

  useEffect(() => {
    if (!contractors.length && !!homeId) {
      dispatch(requestContactList(homeId));
    }
  }, [dispatch, contractors.length, homeId]);

  function DesktopContactsView() {
    return (
      <IonPage>
        <DesktopNavigation>
          <IonRow className="hk-desktop-contacts-search">
            <SearchBar
              value={search}
              onSearchChange={(e) => setSearch(e)}
            ></SearchBar>
          </IonRow>
        </DesktopNavigation>
        <IonContent className="hk-desktop-contacts">
          <LoadingIonList
            className="hk-desktop-contacts-list"
            label="Contacts"
            items={contractors}
            loading={loading}
          >
            {filteredContacts.map((contact: any, i: any) => {
              const { name, category, phone } = contact;
              return (
                <ListItem
                  key={`item-${i}`}
                  startLabel={name.charAt(0)}
                  altView={true}
                  title={name}
                  description={category || 'Sample category'}
                  phone={phone}
                  endIcon={callOutline}
                  onClick={() => window.open(`tel:${phone}`)}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  function MobileContactsView() {
    return (
      <IonPage>
        <MenuHeader title={'Contacts'} />
        <IonContent fullscreen className="hk-contacts">
          <SearchBar
            value={search}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            label="Contacts"
            items={contractors}
            loading={loading}
          >
            {filteredContacts.map((contact: any, i: any) => {
              const { name, category, phone } = contact;
              return (
                <ListItem
                  key={`item-${i}`}
                  startLabel={name.charAt(0)}
                  title={name}
                  description={category || 'Sample category'}
                  endIcon={callOutline}
                  onClick={() => window.open(`tel:${phone}`)}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopContactsView()
        : MobileContactsView()}
    </>
  );
};

export default ContactsList;
