import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum HKDocumentType {
  Photos = 'photos',
  Manuals = 'manuals'
}

export interface Document {
  file_url: string;
  id: number;
  name: string;
  type: HKDocumentType;
  type_id: number;
}

interface DetailState {
  document: Document | null;
  error: string | null;
}

export const initialState: DetailState = {
  document: null,
  error: null
};

export const documentDetailSlice = createSlice({
  name: 'documentDetails',
  initialState,
  reducers: {
    fetchDocumentDetailsSuccess: (state, action: PayloadAction<Document>) => {
      state.document = action.payload;
    },
    fetchDocumentDetailsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    resetDocumentDetails: (state) => {
      state.document = null;
      state.error = null;
    }
  }
});

export const {
  fetchDocumentDetailsSuccess,
  fetchDocumentDetailsError,
  resetDocumentDetails
} = documentDetailSlice.actions;

export default documentDetailSlice.reducer;
