import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import {
  hideAppointmentModal,
  setAppointmentNote,
  setSlideNum
} from './AppointmentWizardSlice';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonText,
  IonTextarea,
  IonToolbar
} from '@ionic/react';
import Illustration from '../../../components/Illustrations/Illustration';
import CalendarIllustration from '../../../assets/illustrations/calendar.svg';
import RocketIllustration from '../../../assets/illustrations/rocket.svg';
import {
  confirmAppointmentProposal,
  confirmRescheduleProposal
} from './AppointmentWizardActions';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import {
  DATE_FORMAT_WEEKDAY_DATE,
  HKPlatform,
  HKScheduleType
} from '../../../core/constants';
import {
  formatTimezoneDate,
  gaScreenView,
  openConcierge
} from '../../../core/util';
import AppointmentProposals from './proposals/AppointmentProposals';
import LeftArrow from '../../../assets/icons/arrow-left.svg';
import Close from '../../../assets/icons/close.svg';
import Chat from '../../../assets/icons/chat.svg';
import './AppointmentWizard.scss';
import { fetchZendeskToken } from 'core/services/UserActions';

const AppointmentWizard: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { zendesk_token_url } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { detailedAppointment } = useSelector(
    (state: RootState) => state.appointment
  );
  const {
    scheduleHomeId,
    proposalHash,
    appointmentNote,
    appointment,
    scheduleType,
    upcomingVisitHash,
    appointmentId,
    slideNum,
    error
  } = useSelector((state: RootState) => state.appointmentWizard);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);

  const { isOpen } = useKeyboardState();
  const dispatch = useDispatch();

  const slideOpts = {
    allowTouchMove: false,
    scrollbar: true,
    initialSlide: 0,
    speed: 400
  };

  const nextSlide = async () => {
    slidesRef.current?.slideNext(400).then(() => slideChanged());
  };

  const prevSlide = () => {
    slidesRef.current?.slidePrev(400).then(() => slideChanged());
  };

  const addNote = (note: string) => {
    dispatch(setAppointmentNote(note));
  };

  const confirm = () => {
    const body = {
      schedule_hash: proposalHash,
      notes: appointmentNote
    };
    if (scheduleType === HKScheduleType.NEW && !!upcomingVisitHash) {
      dispatch(
        confirmAppointmentProposal(scheduleHomeId, upcomingVisitHash, body)
      );
    }
    if (scheduleType === HKScheduleType.RESCHEDULE && !!appointmentId) {
      dispatch(confirmRescheduleProposal(scheduleHomeId, appointmentId, body));
    }
  };

  const updateSlider = () => {
    slidesRef.current?.update();
  };

  const slideChanged = () => {
    slidesRef.current?.getActiveIndex().then((index) => {
      dispatch(setSlideNum(index));
      scrollToTop();
    });
  };

  const scrollToTop = () => {
    contentRef.current?.scrollToTop();
  };

  const openZendeskHelp = () => {
    dispatch(fetchZendeskToken());
    if (!!zendesk_token_url) {
      openConcierge(zendesk_token_url, platformType);
    }
  };

  useEffect(() => {
    if (!!appointment) {
      nextSlide();
    }
  }, [appointment]);

  useEffect(() => {
    gaScreenView('/wizard');
  }, []);

  function DesktopSlideCalendarView() {
    return (
      <>
        {slideNum === 0 && (
          <AppointmentProposals onProposalSelected={nextSlide} />
        )}
      </>
    );
  }

  function MobileSlideCalendarView() {
    return (
      <>
        <Illustration image={CalendarIllustration} />
        <h2>
          {scheduleType === HKScheduleType.NEW
            ? 'What days work best for you?'
            : 'Need to reschedule?'}
        </h2>
        <h6>
          {scheduleType === HKScheduleType.NEW
            ? 'You may select up to 3 dates below to view available appointment times for those days.'
            : 'You can reschedule your appointment to one of the available times below.'}
        </h6>
        {scheduleType === HKScheduleType.RESCHEDULE && (
          <h6 className="hk-appointment-wizard-current">
            Currently scheduled for:
            <IonText color="black" className="ion-text-">
              <b>
                {' '}
                {formatTimezoneDate(
                  detailedAppointment?.scheduled || '',
                  DATE_FORMAT_WEEKDAY_DATE,
                  currentHome?.market_timezone
                )}
              </b>
            </IonText>
          </h6>
        )}
        {slideNum === 0 && (
          <AppointmentProposals onProposalSelected={nextSlide} />
        )}
      </>
    );
  }

  return (
    <IonPage>
      <IonContent
        className={`hk-appointment-wizard ion-padding ${
          slideNum === 0 &&
          platformType === HKPlatform.DESKTOP &&
          isDesktopWidth
            ? 'hk-desktop-appointment-wizard'
            : ''
        }`}
        ref={contentRef}
        fullscreen
        scrollY={true}
      >
        <IonSlides
          pager={false}
          ref={slidesRef}
          options={slideOpts}
          onIonSlidesDidLoad={updateSlider}
          onIonSlideDidChange={slideChanged}
        >
          <IonSlide
            className={`${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? 'hk-desktop-slide-calendar'
                : ''
            }`}
          >
            {platformType === HKPlatform.DESKTOP && isDesktopWidth
              ? DesktopSlideCalendarView()
              : MobileSlideCalendarView()}
          </IonSlide>
          <IonSlide
            className={`${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? 'hk-desktop-slide-notes'
                : ''
            }`}
          >
            <div className="hk-slide-notes-container">
              <Illustration image={CalendarIllustration} />
              <h2>Any entry-access details we should know?</h2>
              <h6>
                Please provide any important home access details (garage code,
                gate access number, parking information, pets on the property,
                etc.
              </h6>
              <IonTextarea
                disabled={loading}
                placeholder="Leave an optional note..."
                value={appointmentNote}
                rows={4}
                onIonChange={(e) => addNote(e.detail.value!)}
              ></IonTextarea>
            </div>
          </IonSlide>
          <IonSlide
            className={`${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? 'hk-desktop-slide-confirmation'
                : ''
            }`}
          >
            <div className="hk-slide-confirmation-container">
              <Illustration image={CalendarIllustration} />
              <h2>You're all set!</h2>
              <h6>
                We'll send you a reminder email 10 days prior to your
                appointment. If you need to reschedule, you can do so on the
                dashboard by selecting your upcoming appointment and clicking
                'reschedule' under appointment details.
              </h6>
              <Illustration
                image={RocketIllustration}
                className="ion-margin-vertical"
              />
            </div>
          </IonSlide>
        </IonSlides>
        {slideNum > 0 && slideNum < 2 && (
          <IonFab vertical="top" horizontal="start">
            <IonFabButton
              color="white"
              className="hk-fab-button ion-margin-top"
              onClick={prevSlide}
            >
              <IonIcon color="medium" icon={LeftArrow} />
            </IonFabButton>
          </IonFab>
        )}
        {slideNum < 2 && (
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'ion-margin-top'
              }`}
              onClick={() => dispatch(hideAppointmentModal())}
            >
              <IonIcon color="medium" icon={Close} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
      {slideNum === 0 && platformType !== HKPlatform.DESKTOP && (
        <IonFab vertical="bottom" horizontal="end" className="ion-padding">
          {zendesk_token_url && (
            <IonFabButton color="primary" onClick={openZendeskHelp}>
              <IonIcon icon={Chat} />
            </IonFabButton>
          )}
        </IonFab>
      )}
      {slideNum === 1 && isOpen === false && (
        <IonToolbar
          className={`hk-appointment-wizard-toolbar ion-padding ${
            platformType === HKPlatform.DESKTOP && isDesktopWidth
              ? 'hk-desktop-slide-toolbar'
              : ''
          }`}
        >
          <IonButton
            disabled={loading}
            expand="block"
            color="primary"
            strong={true}
            className="ion-margin"
            onClick={confirm}
          >
            {loading ? (
              <IonSpinner name="dots" />
            ) : scheduleType === HKScheduleType.NEW ? (
              'Confirm Appointment'
            ) : (
              'Reschedule Appointment'
            )}
          </IonButton>
        </IonToolbar>
      )}
      {slideNum === 2 && (
        <IonToolbar
          className={`hk-appointment-wizard-toolbar ion-padding ${
            platformType === HKPlatform.DESKTOP && isDesktopWidth
              ? 'hk-desktop-slide-toolbar'
              : ''
          }`}
        >
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            className="ion-margin"
            onClick={() => dispatch(hideAppointmentModal())}
          >
            {scheduleType === HKScheduleType.NEW
              ? 'View Dashboard'
              : 'View Appointment'}
          </IonButton>
        </IonToolbar>
      )}
    </IonPage>
  );
};

export default AppointmentWizard;
