import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HKPlatform } from '../constants';
import { DeviceInfo } from '@capacitor/device';
import { Heartbeat } from '../types';

export interface Notification {
  id: string;
  data: NotificationData;
  badge: number;
  subtitle: string;
  title: string;
  body: string;
}

export interface NotificationData {
  hk_type: HKNotificationType;
  hk_id: string;
  hk_data: string;
  aps?: {
    alert: {
      body: string;
      title: string;
    };
  };
}

export enum HKNotificationType {
  AppointmentReminder = 'appointment_reminder',
  CatalogReminder = 'catalog_reminder'
}

interface PlatformState {
  platformType: HKPlatform;
  isDesktopWidth: boolean | null;
  version: string | null;
  apiVersion: string | null;
  deviceInfo: DeviceInfo | null;
  pushNotificationToken: string | null;
  error: string | null;
}

export const initialState: PlatformState = {
  platformType: HKPlatform.DESKTOP,
  isDesktopWidth: null,
  version: null,
  apiVersion: null,
  deviceInfo: null,
  pushNotificationToken: null,
  error: null
};

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setPlatformType: (state, action: PayloadAction<HKPlatform>) => {
      state.platformType = action.payload;
    },
    setIsDesktopWidth: (state, action: PayloadAction<boolean>) => {
      state.isDesktopWidth = action.payload;
    },
    setPushNotificationToken: (state, action: PayloadAction<string>) => {
      state.pushNotificationToken = action.payload;
    },
    setDeviceInfo: (state, action: PayloadAction<DeviceInfo>) => {
      state.deviceInfo = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    updateUserWithPushNotificationTokenSuccess: (state) => {
      state.error = null;
    },
    updateUserWithPushNotificationTokenFailure: (
      state,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
    },
    fetchApiVersionSuccess: (state, action: PayloadAction<Heartbeat>) => {
      state.apiVersion = action.payload.homekeep_version;
    }
  }
});

export const {
  setPlatformType,
  setIsDesktopWidth,
  setVersion,
  setDeviceInfo,
  setPushNotificationToken,
  updateUserWithPushNotificationTokenSuccess,
  updateUserWithPushNotificationTokenFailure,
  fetchApiVersionSuccess
} = platformSlice.actions;

export default platformSlice.reducer;
