import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InventoryItem } from '../../core/types';

interface DetailState {
  loading: boolean;
  product_id: number | null;
  product: InventoryItem | null;
  registration_date: string | null;
  registration_in_progress: boolean;
  error: string | null;
}

export const initialState: DetailState = {
  loading: false,
  product_id: null,
  product: null,
  registration_date: null,
  registration_in_progress: false,
  error: null
};

export const inventoryDetailSlice = createSlice({
  name: 'inventoryDetails',
  initialState,
  reducers: {
    fetchDetailsStart: (state) => {
      state.loading = true;
    },
    fetchProductDetailsSuccess: (state, action: PayloadAction<any>) => {
      // const product = action.payload;
      // product.product_template.assets = [...product.product_template.assets, ...JSON.parse(JSON.stringify(product.product_template.assets))];
      // product.product_template.assets = [...product.product_template.assets, ...JSON.parse(JSON.stringify(product.product_template.assets))];
      state.product = action.payload;
      state.loading = false;
    },
    resetItemDetails: (state) => {
      state.loading = false;
      state.product_id = null;
      state.product = null;
    },
    registrationStart: (state) => {
      state.registration_in_progress = true;
    },
    registrationFinish: (state) => {
      state.registration_in_progress = false;
      state.registration_date = new Date().toDateString();
    },
    showItemDetails: (state, action: PayloadAction<any>) => {
      state.product_id = Number(action.payload);
      state.loading = true;
    }
  }
});

export const {
  fetchDetailsStart,
  fetchProductDetailsSuccess,
  resetItemDetails,
  registrationStart,
  registrationFinish,
  showItemDetails
} = inventoryDetailSlice.actions;

export default inventoryDetailSlice.reducer;
