import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent
} from '@ionic/react';
import Drawer from 'core/layout/Drawer';
import { requestDocumentDetails } from './DocumentActions';

interface DocumentParams {
  id?: string | undefined;
}

const DocumentDetail: React.FC = () => {
  const { id } = useParams<DocumentParams>();
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { document } = useSelector((state: RootState) => state.documentDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!homeId) {
      dispatch(requestDocumentDetails(homeId, Number(id)));
    }
  }, [dispatch, homeId, id]);

  return (
    <Drawer title="Document Details" showCloseButton={true}>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Document</IonCardSubtitle>
            <IonCardTitle>{document?.name}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>{document?.type}</p>
          </IonCardContent>
          <IonButton
            onClick={() => {
              window.open(
                document?.file_url || 'http://www.avidratings.com',
                '_documentView'
              );
            }}
            expand="block"
          >
            Download Asset
          </IonButton>
        </IonCard>
      </IonContent>
    </Drawer>
  );
};

export default DocumentDetail;
