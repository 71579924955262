import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSlide,
  IonSlides,
  IonText
} from '@ionic/react';
import { fetchProductDetails } from './InventoryActions';
import { gaScreenView, viewDocument } from '../../core/util';
import { HKDocumentType } from '../documents/DocumentDetailSlice';
import { resetItemDetails } from './InventoryDetailSlice';
import LoadingSpinner from '../../components/loading/LoadingSpinner';
import Close from '../../assets/icons/close.svg';
import Image from '../../assets/icons/image.svg';
import './InventoryDetail.scss';
import { Asset } from 'core/types';

interface ItemAttribute {
  label: string;
  value: string;
}

const InventoryDetail: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType } = useSelector((state: RootState) => state.platform);
  const { product, product_id, loading } = useSelector(
    (state: RootState) => state.inventoryDetails
  );
  const [attributes, setAttributes] = useState<ItemAttribute[]>([]);
  const dispatch = useDispatch();

  const assets = !!product
    ? [...product.product_template.assets, ...product.assets]
    : [];

  const imageSlideOpts = {
    initialSlide: 0,
    height: 400,
    speed: 400
  };

  const displayAssetThumbnail = (asset: Asset) => {
    return asset.thumb_cloud_store ? asset.thumb_cloud_store : asset.file_url;
  };

  useEffect(() => {
    if (!!product) {
      setAttributes([
        { label: 'Product', value: product.product_template.type },
        { label: 'Brand', value: product.product_template.manufacturer.name },
        { label: 'Model', value: product.product_template.model },
        { label: 'Serial', value: product.serial }
      ]);
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (!product && !!homeId && loading) {
      dispatch(fetchProductDetails(homeId, Number(product_id)));
      gaScreenView('/inventory/details');
    }
  }, [dispatch, product, loading, homeId, product_id]);

  return (
    <IonPage>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <IonContent
          fullscreen
          className="hk-item-detail ion-margin-vertical ion-padding-vertical"
        >
          <IonList className="ion-margin">
            {assets.length ? (
              <IonSlides
                className="hk-item-detail-image-slider"
                pager={true}
                options={imageSlideOpts}
              >
                {assets.map((asset, i) => {
                  return (
                    <IonSlide key={`slide-${i}`}>
                      <img
                        src={displayAssetThumbnail(asset)}
                        alt="Product photo"
                      />
                    </IonSlide>
                  );
                })}
              </IonSlides>
            ) : (
              <IonItem lines="none">
                <img
                  src={Image}
                  alt="No image"
                  className="hk-image-placeholder"
                />
              </IonItem>
            )}
            <IonItem
              lines="none"
              className="hk-item-detail-section-header ion-no-padding"
            >
              <IonText color="dark">Product Details</IonText>
            </IonItem>
            {attributes.map((att, i) => {
              const { label, value } = att;
              return (
                <IonItem
                  key={`feature-${i}`}
                  lines="none"
                  className="hk-item-detail-attribute ion-no-padding"
                >
                  <IonLabel color="medium">{label}</IonLabel>
                  <IonLabel className="ion-text-end ion-no-margin">
                    {value}
                  </IonLabel>
                </IonItem>
              );
            })}
            <IonItem
              lines="none"
              className="hk-item-detail-section-header ion-no-padding"
            >
              <IonText color="dark">Documents</IonText>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding">
              <div className="hk-item-detail-document-slider">
                {product?.product_template.assets
                  .filter((asst) => asst.type === HKDocumentType.Manuals)
                  .map((attr, i) => {
                    const { name, file_url } = attr;
                    return (
                      <div
                        className="hk-item-detail-document-slider-slide"
                        key={`slide-${i}`}
                      >
                        <IonImg
                          src={product.product_template.manufacturer.image}
                          className="hk-item-detail-document-slider-slide-img"
                          onClick={() => viewDocument(file_url, platformType)}
                        />
                        <IonText
                          color="medium"
                          className="hk-item-detail-document-slider-slide-name ion-text-center"
                        >
                          <p>{name}</p>
                        </IonText>
                      </div>
                    );
                  })}
              </div>
            </IonItem>
            <IonItem lines="none" className="hk-section-header ion-no-padding">
              <IonText color="dark">Maintenance</IonText>
            </IonItem>
          </IonList>
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="white"
              className="hk-fab-button ion-margin-top"
              onClick={() => dispatch(resetItemDetails())}
            >
              <IonIcon color="medium" icon={Close} />
            </IonFabButton>
          </IonFab>
        </IonContent>
      )}
    </IonPage>
  );
};

export default InventoryDetail;
