import axios from 'axios';

import { replaceParams, isDev, sleep, getBaseApiUrl } from './helpers';
import { AppThunk, AppDispatch } from 'core/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import store from 'core/store';
import { displayError } from 'core/services/AlertActions';
import { logout } from '../../features/login/LoginSlice';
import { TWO_SECONDS } from 'core/constants';
import { setLoading } from '../services/LoadingSlice';

interface ApiUrlOptions {
  route: string;
  replace?: Array<any>;
}

interface AsyncActionOptions {
  // Required Params:
  route: string;

  // Optional Params:
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  replace?: Array<any>;
  body?: Object;
  defaultErrorMessage?: string;
  onStart?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onSuccess?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onError?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onComplete?: Function;
  useGlobalLoader?: boolean;
}

const getToken = () => {
  const { login } = store.getState();
  return login.accessToken;
};

export const constructApiUrl = (options: ApiUrlOptions): string => {
  const { route, replace } = options;
  const apiBaseUrl = getBaseApiUrl();

  if (isDev() && route[0] !== '/') {
    console.warn("Routes must start with '/'");
  }

  let url = apiBaseUrl;
  url += replace && replace.length ? replaceParams(route, replace) : route;

  return String(url);
};

export const createApiRequest = (options: AsyncActionOptions): Function => {
  const {
    route,
    replace,
    body,
    method,
    onStart,
    onSuccess,
    onComplete,
    onError,
    useGlobalLoader,
    defaultErrorMessage
  } = options;
  return (): AppThunk => async (dispatch: AppDispatch) => {
    const url = constructApiUrl({ route, replace });
    // console.log(url);
    if (typeof onStart === 'function') {
      dispatch(onStart(true));
    }
    if (useGlobalLoader) {
      dispatch(setLoading(true));
    }
    if (Array.isArray(onStart) && typeof onStart[0] === 'function') {
      const params = onStart.slice(1);
      dispatch(onStart[0](...params));
    }
    try {
      const token = getToken();
      const request = await axios({
        method: method || 'get',
        url,
        headers: {
          Authorization: token ? `Bearer ${token}` : null
        },
        data: body
      });
      const results = request.data.results || request.data;
      if (typeof onSuccess === 'function') {
        dispatch(onSuccess(results));
      }
      if (typeof onComplete === 'function') {
        dispatch(onComplete());
      }
      if (useGlobalLoader) {
        dispatch(setLoading(false));
        // await sleep(HALF_SECOND); //NOTE: this waits for our loading animation to finish
      }
    } catch (e: any) {
      if (typeof onError === 'function') dispatch(onError(e.message));
      if (useGlobalLoader) {
        dispatch(setLoading(false));
      }
      dispatch(displayError(defaultErrorMessage || e.message));
      // clear the long-life token
      if (
        !!e.response &&
        (e.response.status === 401 || e.response.status === 422)
      ) {
        await sleep(TWO_SECONDS);
        dispatch(logout());
      }
      if (isDev()) {
        console.error(
          `Homebook API Message:\n URL: ${url}\n Error: ${e.message}`
        );
      }
    }
  };
};
