import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'core/store';
import {
  IonChip,
  IonContent,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow
} from '@ionic/react';
import { filterAppointments, getVisitIcon } from 'core/util/helpers';
import { fetchUpcomingVisits, fetchAppointmentList } from './DashboardActions';
import {
  hideAppointmentModal,
  resetAppointmentWizard,
  showAppointmentWizard
} from '../appointments/wizard/AppointmentWizardSlice';
import { registerForPushNotifications } from '../../core/services/PushNotificatons';
import { setPushNotificationToken } from '../../core/services/PlatformSlice';
import { removeDetailedAppointment } from '../appointments/AppointmentDetailsSlice';
import { loadFirebaseTokenFromStorage } from '../../core/util';
import { HKPlatform, HKScheduleType } from '../../core/constants';
import MenuHeader from '../../core/layout/menu-header/MenuHeader';
import Illustration from '../../components/Illustrations/Illustration';
import LoadingIonList from '../../core/layout/LoadingIonList';
import AppointmentCard from '../../components/cards/appointment/AppointmentCard';
import AppointmentWizard from '../appointments/wizard/AppointmentWizard';
import VisitCard from 'components/cards/visit/VisitCard';
import HomeIllustration from '../../assets/illustrations/dashboard-home.svg';
import Calendar from '../../assets/illustrations/calendar.svg';
import Clock from '../../assets/icons/clock.svg';
import DownArrow from '../../assets/icons/arrow-down.svg';
import './Dashboard.scss';
import { resetAppointmentList, resetVisitsList } from './DashboardSlice';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';

const Dashboard: React.FC = () => {
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { pushNotificationToken, platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { modalVisible } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { upcomingVisits, appointments, loading } = useSelector(
    (state: RootState) => state.dashboard
  );
  const [toggleIndex, setToggleIndex] = useState(0);
  const refresherRef = useRef<HTMLIonRefresherElement>(null);
  const toggleOptions = ['Up Next', 'History'];
  const dispatch = useDispatch();
  const history = useHistory();

  const refreshAppointmentList = () => {
    dispatch(resetAppointmentList());
    dispatch(resetVisitsList());
  };

  const viewAppointment = (id: number) => {
    if (id !== undefined) {
      dispatch(removeDetailedAppointment());
      history.push(`/appointment/${id}`);
    }
  };

  const scheduleUpcoming = (homeId: number, hash: string, name: string) => {
    if (hash !== undefined) {
      dispatch(resetAppointmentWizard());
      dispatch(
        showAppointmentWizard({
          type: HKScheduleType.NEW,
          home_id: homeId,
          visit_hash: hash,
          name: name
        })
      );
    }
  };

  useEffect(() => {
    if (!pushNotificationToken) {
      loadFirebaseTokenFromStorage().then((token) => {
        if (!token) {
          registerForPushNotifications();
        } else {
          dispatch(setPushNotificationToken(token));
        }
      });
    }
  }, [pushNotificationToken]);

  useEffect(() => {
    if (!loading && !!refresherRef) {
      refresherRef.current?.complete();
    }
  }, [dispatch, loading, refresherRef]);

  useEffect(() => {
    if (!!currentHome && !appointments) {
      dispatch(fetchAppointmentList(currentHome.id));
    }
  }, [dispatch, currentHome, appointments]);

  useEffect(() => {
    if (!!currentHome && !upcomingVisits) {
      dispatch(fetchUpcomingVisits(currentHome.id));
    }
  }, [dispatch, currentHome, upcomingVisits]);

  function DesktopDashboardView() {
    return (
      <>
        <DesktopNavigation>
          <IonRow className="hk-desktop-dashboard-toggle">
            {toggleOptions.map((opt, i) => {
              return (
                <div
                  key={`toggle-item-${i}`}
                  className={toggleIndex === i ? 'selected' : ''}
                  onClick={() => {
                    setToggleIndex(i);
                  }}
                >
                  {opt}
                </div>
              );
            })}
          </IonRow>
        </DesktopNavigation>
        <IonContent>
          <LoadingIonList
            label={toggleIndex ? 'Past Appointments' : 'Upcoming Appointments'}
            items={
              toggleIndex
                ? filterAppointments(toggleIndex, appointments || [])
                : [
                    ...filterAppointments(toggleIndex, appointments || []),
                    ...(upcomingVisits || [])
                  ]
            }
            emptyImage={Calendar}
            loading={loading}
            className="hk-desktop-dashboard-appointments"
          >
            {!!appointments &&
              filterAppointments(toggleIndex, appointments).map((appt, i) => {
                return (
                  <AppointmentCard
                    key={'appt' + i}
                    appointment={appt}
                    timezone={currentHome?.market_timezone || ''}
                    icon={Clock}
                    onClick={() => viewAppointment(appt.id)}
                  />
                );
              })}
            {toggleIndex === 0 &&
              upcomingVisits?.map((visit, i) => {
                return (
                  <VisitCard
                    key={'visit' + i}
                    icon={getVisitIcon(visit.season)}
                    visit={visit}
                    onClick={() =>
                      scheduleUpcoming(
                        visit.home_id,
                        visit.plan_hash,
                        visit.name
                      )
                    }
                  />
                );
              })}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  function MobileDashboardView() {
    return (
      <>
        <MenuHeader>
          <div className="hk-dashboard">
            <div className="hk-dashboard-image">
              <Illustration image={HomeIllustration} />
            </div>
            <div className="hk-dashboard-toggle">
              {toggleOptions.map((opt, i) => {
                return (
                  <IonChip
                    key={'chip-' + i}
                    className={toggleIndex === i ? 'selected' : ''}
                    onClick={() => {
                      setToggleIndex(i);
                    }}
                  >
                    {opt}
                  </IonChip>
                );
              })}
            </div>
          </div>
        </MenuHeader>
        <IonContent className="ion-padding-vertical">
          <IonRefresher
            className="hk-refresher ion-padding"
            ref={refresherRef}
            slot="fixed"
            pullFactor={0.6}
            pullMin={100}
            onIonRefresh={refreshAppointmentList}
          >
            <IonRefresherContent
              className="ion-margin-top"
              pullingIcon={DownArrow}
              pullingText="Pull to refresh"
              refreshingText="Refreshing..."
            />
          </IonRefresher>
          <LoadingIonList
            label={toggleIndex ? 'Past Appointments' : 'Upcoming Appointments'}
            items={
              toggleIndex
                ? filterAppointments(toggleIndex, appointments || [])
                : [
                    ...filterAppointments(toggleIndex, appointments || []),
                    ...(upcomingVisits || [])
                  ]
            }
            emptyImage={Calendar}
            loading={loading}
            className="ion-margin-vertical"
          >
            {!!appointments &&
              filterAppointments(toggleIndex, appointments).map((appt, i) => {
                return (
                  <AppointmentCard
                    key={'appt' + i}
                    appointment={appt}
                    timezone={currentHome?.market_timezone || ''}
                    icon={Clock}
                    onClick={() => viewAppointment(appt.id)}
                  />
                );
              })}
            {toggleIndex === 0 &&
              upcomingVisits?.map((visit, i) => {
                return (
                  <VisitCard
                    key={'visit' + i}
                    icon={getVisitIcon(visit.season)}
                    visit={visit}
                    onClick={() =>
                      scheduleUpcoming(
                        visit.home_id,
                        visit.plan_hash,
                        visit.name
                      )
                    }
                  />
                );
              })}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
        <DesktopDashboardView />
      ) : (
        <MobileDashboardView />
      )}
      <IonModal
        cssClass="hk-appointment-wizard-modal"
        isOpen={modalVisible}
        onDidDismiss={() => {
          dispatch(hideAppointmentModal());
        }}
      >
        <AppointmentWizard />
      </IonModal>
    </IonPage>
  );
};

export default Dashboard;
