import { Storage } from '@capacitor/storage';

// Keys
export const AUTH_TOKEN_KEY = 'auth_token';
export const TUTORIAL_VIEWED_KEY = 'tutorial_viewed';
export const FIREBASE_TOKEN_KEY = 'firebase_token';

export async function saveAuthTokenInStorage(token: string) {
  await Storage.set({
    key: AUTH_TOKEN_KEY,
    value: token
  });
}

export async function loadAuthTokenFromStorage() {
  const { value } = await Storage.get({ key: AUTH_TOKEN_KEY });
  return value;
}

export async function removeAuthTokenFromStorage() {
  await Storage.remove({ key: AUTH_TOKEN_KEY });
}

export async function saveTutorialViewdInStorage() {
  await Storage.set({
    key: TUTORIAL_VIEWED_KEY,
    value: 'true'
  });
}

export async function loadTutorialViewedFromStorage() {
  const { value } = await Storage.get({ key: TUTORIAL_VIEWED_KEY });
  return value === 'true';
}

export async function saveFirebaseTokenInStorage(token: string) {
  await Storage.set({
    key: FIREBASE_TOKEN_KEY,
    value: token
  });
}

export async function loadFirebaseTokenFromStorage() {
  const { value } = await Storage.get({ key: FIREBASE_TOKEN_KEY });
  return value;
}

export async function removeFirebaseTokenFromStorage() {
  await Storage.remove({ key: FIREBASE_TOKEN_KEY });
}
