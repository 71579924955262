import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PRIMARY } from 'core/constants';

interface InitialState {
  isOpen: boolean;
  intent: string;
  title: string;
  message: string;
}

export const initialState: InitialState = {
  isOpen: false,
  intent: '',
  title: '',
  message: ''
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    displayAlert: (state, action: PayloadAction<any>) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.intent = action.payload.intent || PRIMARY;
    },
    hideAlert: (state) => {
      state.isOpen = false;
      state.message = '';
      state.title = '';
      state.intent = '';
    }
  }
});

export const { displayAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
