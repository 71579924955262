import { configureStore } from '@reduxjs/toolkit';
import { Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import loadingReducer from 'core/services/LoadingSlice';
import homeReducer from 'core/services/HomeSlice';
import loginReducer from 'features/login/LoginSlice';
import menuReducer from 'core/services/MenuSlice';
import userReducer from 'core/services/UserSlice';
import contactListReducer from 'features/contacts/ContactListSlice';
import inventoryReducer from 'features/inventory/InventorySlice';
import documentsReducer from 'features/documents/DocumentSlice';
import inventoryDetailsReducer from 'features/inventory/InventoryDetailSlice';
import documentDetailsReducer from 'features/documents/DocumentDetailSlice';
import appointmentWizardReducer from '../features/appointments/wizard/AppointmentWizardSlice';
import appointmentReducer from '../features/appointments/AppointmentDetailsSlice';
import dashboardReducer from 'features/dashboard/DashboardSlice';
import platformReducer from 'core/services/PlatformSlice';
import alertReducer from 'core/services/AlertSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

export const rootReducer = combineReducers({
  loading: loadingReducer,
  alert: alertReducer,
  login: loginReducer,
  menu: menuReducer,
  home: homeReducer,
  user: userReducer,
  platform: platformReducer,
  dashboard: dashboardReducer,
  appointment: appointmentReducer,
  appointmentWizard: appointmentWizardReducer,
  inventoryDetails: inventoryDetailsReducer,
  documentDetails: documentDetailsReducer,
  contacts: contactListReducer,
  inventory: inventoryReducer,
  documents: documentsReducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: [thunk]
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
