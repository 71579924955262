import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import MenuLayout from 'core/layout/MenuLayout';
import PrivateRoute from './PrivateRoute';
import Login from 'features/login/Login';
import Dashboard from 'features/dashboard/Dashboard';
import InventoryList from 'features/inventory/InventoryList';
import ContactsList from 'features/contacts/ContactList';
import DocumentDetail from 'features/documents/DocumentDetail';
import Tutorial from 'features/tutorial/Tutorial';
import InitialApppointment from '../../features/appointments/initial/InitialAppointment';
import InventoryGroupList from 'features/inventory/InventoryGroupList';
import DocumentGroupList from 'features/documents/DocumentGroupList';
import DocumentList from 'features/documents/DocumentList';
import Account from '../../features/account/Account';
import AppointmentDetails from 'features/appointments/AppointmentDetails';
import Checkpoint from '../../features/login/Checkpoint';
import Update from '../../features/update/Update';
import PasswordReset from '../../features/login/PasswordReset';
import InventoryDetailsDesktop from 'features/inventory/InventoryDetailsDesktop';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/update" component={Update} />
      <Route path="/login" component={Login} />
      <Route path="/reset" component={PasswordReset} />
      <Route path="/auth/:code" component={Login} />
      <Route path="/tutorial" component={Tutorial} />
      <PrivateRoute path="/checkpoint">
        <Checkpoint />
      </PrivateRoute>
      <PrivateRoute path="/dashboard">
        <MenuLayout>
          <Dashboard />
        </MenuLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/initial">
        <InitialApppointment />
      </PrivateRoute>
      <PrivateRoute exact path="/account">
        <Account />
      </PrivateRoute>
      <PrivateRoute exact path="/appointment/:id">
        <AppointmentDetails />
      </PrivateRoute>
      <PrivateRoute exact path="/inventory">
        <MenuLayout>
          <InventoryGroupList />
        </MenuLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/inventory/group/:id">
        <InventoryList />
      </PrivateRoute>
      <PrivateRoute exact path="/inventory/group/:groupId/product/:productId">
        <InventoryDetailsDesktop />
      </PrivateRoute>
      <PrivateRoute exact path="/contacts">
        <MenuLayout>
          <ContactsList />
        </MenuLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/documents">
        <MenuLayout>
          <DocumentGroupList />
        </MenuLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/documents/group/:type">
        <DocumentList />
      </PrivateRoute>
      <PrivateRoute exact path="/documents/detail/:id">
        <DocumentDetail />
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
