import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InventoryGroup, InventoryItem } from 'core/types';

export interface ProductGroup {
  id: number;
  group: string;
  count: number;
}

interface InventoryState {
  productGroups: ProductGroup[] | null;
  products: InventoryItem[] | null;
  product_count: number | null;
  registered_product_count: number | null;
  registration_in_progress: boolean;
  deregistration_in_progress: boolean;
  allItemsRegistered: boolean;
  inventory: InventoryGroup[] | null;
  loading: boolean;
}

export const initialState: InventoryState = {
  productGroups: null,
  products: null,
  registration_in_progress: false,
  deregistration_in_progress: false,
  product_count: null,
  registered_product_count: null,
  allItemsRegistered: false,
  inventory: null,
  loading: false
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    fetchProductGroupCountSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.productGroups = items;
    },
    fetchProductGroupStart: (state, action: PayloadAction<InventoryItem[]>) => {
      state.loading = true;
    },
    fetchProductGroupSuccess: (
      state,
      action: PayloadAction<InventoryItem[]>
    ) => {
      state.products = action.payload;
      state.loading = false;
    },
    resetProductGroup: (state) => {
      state.products = null;
    },
    fetchHomeSuccess: (state, action: PayloadAction<any>) => {
      const { product_count, registered_product_count } = action.payload;
      state.product_count = product_count;
      state.registered_product_count = registered_product_count;
      if (product_count === registered_product_count) {
        state.allItemsRegistered = true;
      }
    },
    registerAllProductsStart: (state) => {
      state.registration_in_progress = true;
    },
    registerAllProductsFinish: (state, action: PayloadAction<any>) => {
      state.registration_in_progress = false;
      state.registered_product_count = state.product_count;
      state.allItemsRegistered = true;
    },
    deRegisterAllProductsStart: (state) => {
      state.deregistration_in_progress = true;
    },
    deRegisterAllProductsFinish: (state) => {
      state.deregistration_in_progress = false;
      state.allItemsRegistered = false;
      state.product_count = null;
    },
    incrementItemCount: (state) => {
      if (typeof state.registered_product_count === 'number') {
        state.registered_product_count++;
      } else {
        state.registered_product_count = 1;
      }
    }
  }
});

export const {
  fetchProductGroupCountSuccess,
  fetchProductGroupStart,
  fetchProductGroupSuccess,
  resetProductGroup,
  fetchHomeSuccess,
  registerAllProductsStart,
  registerAllProductsFinish,
  deRegisterAllProductsStart,
  deRegisterAllProductsFinish,
  incrementItemCount
} = inventorySlice.actions;

export default inventorySlice.reducer;
