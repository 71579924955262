import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Home {
  id: number;
  name: string;
  catalog_status: string;
  description: string;
  image: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  market_timezone: string;
  state: string;
  created: string;
  subscriptions: Subscription[];
  type: string;
  type_id: number;
}

export interface Subscription {
  name: string;
  id: number;
}

interface HomeState {
  homes: Home[] | null;
  currentHome: Home | null;
}

export const initialState: HomeState = {
  homes: null,
  currentHome: null
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomes: (homeState, action: PayloadAction<any>) => {
      homeState.homes = action.payload;
      if (homeState.homes?.length) {
        homeState.currentHome = homeState.homes[0];
      }
    },
    updateHomeSuccess: (homeState, action: PayloadAction<any>) => {
      const home = action.payload;
      const currentHome = homeState.homes?.find((h) => h.id === home.id);
      if (currentHome) {
        homeState.homes?.splice(homeState.homes.indexOf(currentHome), 1, home);
        homeState.currentHome = home;
      }
    },
    updateHomeFailure: (homeState, action: PayloadAction<any>) => {}
  }
});

export const { setHomes, updateHomeSuccess, updateHomeFailure } =
  homeSlice.actions;

// What's up, HomeSlice?
export default homeSlice.reducer;
