import { createApiRequest } from '../../core/util';
import {
  APPOINTMENT_DETAILS_URL,
  APPOINTMENT_TASK_LIST_URL,
  STATUS_TAG_LIST_URL
} from '../../core/constants';
import {
  fetchAppointmentDetailsError,
  fetchAppointmentDetailsStart,
  fetchAppointmentDetailsSuccess,
  fetchAppointmentTaskListError,
  fetchAppointmentTaskListStart,
  fetchAppointmentTaskListSuccess,
  fetchStatusTagListError,
  fetchStatusTagListSuccess
} from './AppointmentDetailsSlice';

export const fetchAppointmentDetails = (
  homeId: number,
  appointmentId: number
) =>
  createApiRequest({
    route: APPOINTMENT_DETAILS_URL,
    method: 'get',
    replace: [
      ['home_id', homeId],
      ['appt_id', appointmentId]
    ],
    onStart: fetchAppointmentDetailsStart,
    onSuccess: fetchAppointmentDetailsSuccess,
    onError: fetchAppointmentDetailsError,
    defaultErrorMessage: 'There was a problem fetching your appointment.'
  })();

export const fetchAppointmentTaskList = (
  homeId: number,
  appointmentId: number
) =>
  createApiRequest({
    route: APPOINTMENT_TASK_LIST_URL,
    method: 'get',
    replace: [
      ['home_id', homeId],
      ['appt_id', appointmentId]
    ],
    onStart: fetchAppointmentTaskListStart,
    onSuccess: fetchAppointmentTaskListSuccess,
    onError: fetchAppointmentTaskListError,
    defaultErrorMessage:
      'There was a problem fetching the tasks for your appointment.'
  })();

export const fetchStatusTagList = (homeId: number, appointmentId: number) =>
  createApiRequest({
    route: STATUS_TAG_LIST_URL,
    method: 'get',
    onSuccess: fetchStatusTagListSuccess,
    onError: fetchStatusTagListError
  })();
