import React from 'react';
import HomeKeepLogo from 'assets/illustrations/hk-logo-horizontal-light.svg';
import './DesktopNavigation.scss';
import Illustration from 'components/Illustrations/Illustration';
import { IonIcon, IonItem, IonRow } from '@ionic/react';
import HomeIcon from 'assets/icons/home.svg';
import InventoryIcon from 'assets/icons/inventory.svg';
import ContactsIcon from 'assets/icons/contacts.svg';
import DocumentsIcon from 'assets/icons/documents.svg';
import UserIcon from 'assets/icons/user.svg';
import HomeIllustration from 'assets/illustrations/dashboard-home.svg';
import { useHistory, useLocation } from 'react-router';

const DesktopNavigation: React.FC = ({ children, ...rest }) => {
  interface Page {
    title: string;
    path: string;
    icon: string;
  }

  const pages: Page[] = [
    { title: 'Dashboard', path: '/dashboard', icon: String(HomeIcon) },
    { title: 'Contacts', path: '/contacts', icon: String(ContactsIcon) },
    { title: 'Inventory', path: '/inventory', icon: String(InventoryIcon) },
    { title: 'Documents', path: '/documents', icon: String(DocumentsIcon) },
    { title: 'Account', path: '/account', icon: String(UserIcon) }
  ];

  const location = useLocation();
  const history = useHistory();

  const navigateTo = (route: string) => {
    history.push(route);
  };

  return (
    <div className="hk-desktop-nav-header-container" {...rest}>
      <div className="hk-desktop-nav-header">
        <div className="hk-desktop-nav-header-top">
          <div onClick={() => navigateTo('/dashboard')}>
            <Illustration
              image={HomeKeepLogo}
              className="hk-desktop-nav-header-top-logo"
            />
          </div>
          <IonRow className="hk-desktop-nav-header-top-nav">
            {pages.map((page, i) => {
              return (
                <IonItem
                  key={`menu-item-${i}`}
                  className={
                    location.pathname.includes(page.path) ? 'selected' : ''
                  }
                  onClick={() => navigateTo(page.path)}
                >
                  <IonIcon icon={page.icon} slot="start" />
                  {page.title}
                </IonItem>
              );
            })}
          </IonRow>
        </div>
        <div className="hk-desktop-nav-header-bottom">
          <div className="hk-desktop-nav-header-bottom-content">{children}</div>
          <Illustration
            image={HomeIllustration}
            className="hk-desktop-nav-header-bottom-image"
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopNavigation;
