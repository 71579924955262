import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonSlide,
  IonSlides,
  IonToolbar
} from '@ionic/react';
import {
  arrowForward,
  chevronBackOutline,
  chevronForwardOutline
} from 'ionicons/icons';
import Illustration from 'components/Illustrations/Illustration';
import ScheduleIllustration from 'assets/illustrations/schedule.svg';
import CatalogIllustration from 'assets/illustrations/catalog.svg';
import RelaxIllustration from 'assets/illustrations/relax.svg';
import { saveTutorialViewdInStorage } from '../../core/util';
import './Tutorial.scss';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

const Tutorial: React.FC = () => {
  const [slide, setSlide] = useState(0);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  const slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  const nextSlide = () => {
    slidesRef.current?.slideNext(400);
  };

  const prevSlide = () => {
    slidesRef.current?.slidePrev(400);
  };

  const slideChanged = () => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setSlide(index);
    });
  };

  useEffect(() => {
    saveTutorialViewdInStorage().then();
  }, []);

  function DesktopTutorialView() {
    return (
      <IonPage>
        <IonToolbar className="hk-desktop-tutorial-header">
          <IonButtons slot="end">
            <Link to="/initial">
              <IonButton color="medium">skip</IonButton>
            </Link>
          </IonButtons>
        </IonToolbar>
        <IonContent
          fullscreen
          className="hk-desktop-tutorial ion-padding"
          scroll-y="false"
        >
          <IonSlides
            ref={slidesRef}
            pager={true}
            options={slideOpts}
            onIonSlideDidChange={() => slideChanged()}
          >
            <IonSlide>
              <Illustration image={ScheduleIllustration} />
              <section>
                <h2>Schedule your first appointment</h2>
                <p>
                  Pick the time and day that works best for a HomeKeep rep to
                  meet you at your home.
                </p>
              </section>
            </IonSlide>
            <IonSlide>
              <Illustration image={CatalogIllustration} />
              <section>
                <h2>Get your home inventoried</h2>
                <p>
                  Have your home products cataloged in the app and registered
                  under warranty.
                </p>
              </section>
            </IonSlide>
            <IonSlide>
              <Illustration image={RelaxIllustration} />
              <section>
                <h2>Put your home maintenance on autopilot</h2>
                <p>
                  Make yourself at home while we take care of your maintenance
                  needs year-round.
                </p>
              </section>
            </IonSlide>
          </IonSlides>
          <div className="hk-tutorial-buttons">
            <IonButton
              disabled={slide === 0}
              color="medium"
              className="hk-tutorial-buttons-back"
              onClick={() => prevSlide()}
            >
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
            {slide < 2 && (
              <IonButton
                className="hk-tutorial-buttons-next"
                onClick={() => nextSlide()}
              >
                Next <IonIcon icon={chevronForwardOutline} />
              </IonButton>
            )}
            {slide === 2 && (
              <Link to="/initial">
                <IonButton className="hk-tutorial-buttons-next">
                  Next
                  <IonIcon icon={chevronForwardOutline} />
                </IonButton>
              </Link>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileTutorialView() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar className="tutorial-toolbar">
            {slide > 0 && (
              <IonButtons slot="start">
                <IonButton color="medium" onClick={() => prevSlide()}>
                  <IonIcon icon={chevronBackOutline} />
                </IonButton>
              </IonButtons>
            )}
            <IonButtons slot="end">
              <Link to="/initial">
                <IonButton color="medium">skip</IonButton>
              </Link>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          fullscreen
          className="hk-tutorial ion-padding"
          scroll-y="false"
        >
          <IonSlides
            ref={slidesRef}
            pager={true}
            options={slideOpts}
            onIonSlideDidChange={() => slideChanged()}
          >
            <IonSlide>
              <Illustration image={ScheduleIllustration} />
              <section>
                <h2>Schedule your first appointment</h2>
                <p>
                  Pick the time and day that works best for a HomeKeep rep to
                  meet you at your home.
                </p>
              </section>
            </IonSlide>
            <IonSlide>
              <Illustration image={CatalogIllustration} />
              <section>
                <h2>Get your home inventoried</h2>
                <p>
                  Have your home products cataloged in the app and registered
                  under warranty.
                </p>
              </section>
            </IonSlide>
            <IonSlide>
              <Illustration image={RelaxIllustration} />
              <section>
                <h2>Put your home maintenance on autopilot</h2>
                <p>
                  Make yourself at home while we take care of your maintenance
                  needs year-round.
                </p>
              </section>
            </IonSlide>
          </IonSlides>
          <IonFab vertical="bottom" horizontal="end">
            {slide < 2 && (
              <IonFabButton onClick={() => nextSlide()}>
                <IonIcon icon={arrowForward} />
              </IonFabButton>
            )}
            {slide === 2 && (
              <Link to="/initial">
                <IonButton strong={true}>Get Started</IonButton>
              </Link>
            )}
          </IonFab>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopTutorialView()
        : MobileTutorialView()}
    </>
  );
};

export default Tutorial;
