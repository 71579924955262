import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store';
import { useHistory } from 'react-router';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonText
} from '@ionic/react';
import { showAppointmentWizard } from '../wizard/AppointmentWizardSlice';
import { fetchUpcomingVisits } from '../../dashboard/DashboardActions';
import { HKScheduleType } from '../../../core/constants';
import CalendarIllustration from '../../../assets/illustrations/calendar.svg';
import AppointmentWizard from '../wizard/AppointmentWizard';
import './InitialAppointment.scss';

const InitialAppointment: React.FC = () => {
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { modalVisible, appointment } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { upcomingVisits } = useSelector((state: RootState) => state.dashboard);
  const { name } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyAppointment = () => {
    if (!!appointment) {
      history.push('/dashboard');
    }
  };

  const scheduleInitialAppointment = () => {
    if (!!upcomingVisits) {
      dispatch(
        showAppointmentWizard({
          type: HKScheduleType.NEW,
          home_id: currentHome?.id,
          visit_hash: upcomingVisits[0].plan_hash,
          name: upcomingVisits[0].name
        })
      );
    }
  };

  useEffect(() => {
    if (!upcomingVisits && !!currentHome) {
      dispatch(fetchUpcomingVisits(currentHome.id));
    }
  }, [dispatch, upcomingVisits, currentHome]);

  return (
    <IonPage>
      <IonContent fullscreen scroll-y="false" className="hk-schedule">
        <section className="hk-schedule-header ion-padding">
          <IonText className="hk-schedule-header-text" color="light">
            <h1>Welcome</h1>
          </IonText>
          <IonText className="hk-schedule-header-text name" color="light">
            <h1>{name}</h1>
          </IonText>
          <IonText color="light">
            <p>
              Thanks for setting up your HomeKeep account. We're looking forward
              to caring for your home! First things first, let's schedule your
              initial home cataloging appointment.
            </p>
          </IonText>
        </section>
        <section className="hk-schedule-body ion-padding-top">
          <IonCard className="ion-padding-top ion-padding-bottom hk-grey-background">
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <img
                      className="hk-card-image"
                      src={CalendarIllustration}
                      alt="Calendar"
                    />
                  </IonCol>
                  <IonCol size="8">
                    <IonText color="light">
                      <h1 className="hk-card-text">
                        Schedule your cataloging appointment
                      </h1>
                    </IonText>
                    <IonText color="light">
                      <h3 className="hk-card-text">
                        Select a day and time that works best for you.
                      </h3>
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      disabled={upcomingVisits?.length === 0}
                      expand="block"
                      color="primary"
                      className="ion-margin-vertical"
                      onClick={scheduleInitialAppointment}
                    >
                      {upcomingVisits?.length === 1 ? (
                        'Schedule'
                      ) : (
                        <IonSpinner name="dots" />
                      )}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </section>
      </IonContent>
      <IonModal isOpen={modalVisible} onDidDismiss={() => verifyAppointment()}>
        <AppointmentWizard />
      </IonModal>
    </IonPage>
  );
};

export default InitialAppointment;
