import React from 'react';
import { IonFab, IonFabButton, IonIcon, IonRouterOutlet } from '@ionic/react';
import SideMenu from './side-menu/SideMenu';
import Chat from '../../assets/icons/chat.svg';
import { openConcierge } from 'core/util';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { fetchZendeskToken } from 'core/services/UserActions';

const MenuLayout: React.FC = ({ children }) => {
  const { platformType } = useSelector((state: RootState) => state.platform);
  const { zendesk_token_url } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const openZendeskHelp = () => {
    dispatch(fetchZendeskToken());
    if (!!zendesk_token_url) {
      openConcierge(zendesk_token_url, platformType);
    }
  };

  return (
    <>
      <SideMenu />
      <IonRouterOutlet id="main-content">{children}</IonRouterOutlet>
      <IonFab vertical="bottom" horizontal="end" className="ion-padding">
        {zendesk_token_url && (
          <IonFabButton onClick={openZendeskHelp}>
            <IonIcon icon={Chat} color="white" />
          </IonFabButton>
        )}
      </IonFab>
    </>
  );
};

export default MenuLayout;
