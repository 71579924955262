import { createApiRequest } from 'core/util';
import { AUTH_FIREBASE_URL } from 'core/constants';

import {
  requestTokenForFirebaseUserStart,
  requestTokenForFirebaseUserSuccess,
  requestTokenForFirebaseUserError
} from './LoginSlice';

export const requestTokenForFirebaseUser = (userToken: string) =>
  createApiRequest({
    route: AUTH_FIREBASE_URL,
    method: 'post',
    body: { id_token: userToken },
    onStart: requestTokenForFirebaseUserStart,
    onSuccess: requestTokenForFirebaseUserSuccess,
    onError: requestTokenForFirebaseUserError,
    defaultErrorMessage: 'Unable to Log In. Please try again.'
    // useGlobalLoader: true
  })();
