import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonContent,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow
} from '@ionic/react';
import { RootState } from '../../core/store';
import { useParams } from 'react-router';
import {
  DATE_FORMAT_WEEKDAY_DATE,
  HKPlatform,
  HKScheduleType
} from '../../core/constants';
import {
  fetchAppointmentDetails,
  fetchAppointmentTaskList
} from './AppointmentDetailActions';
import {
  formatAppointmentDisplayTimeSlot,
  formatTimezoneDate
} from 'core/util/helpers';
import {
  hideAppointmentModal,
  resetAppointmentWizard,
  showAppointmentWizard
} from './wizard/AppointmentWizardSlice';
import {
  removeDetailedAppointment,
  setModalTask
} from './AppointmentDetailsSlice';
import ServicePersonCard from '../../components/cards/service-person/ServicePersonCard';
import TaskItem from '../../components/items/task-item/TaskItem';
import LoadingSpinner, {
  LoadingMode
} from '../../components/loading/LoadingSpinner';
import BackButtonHeader from '../../core/layout/BackButtonHeader';
import AppointmentWizard from './wizard/AppointmentWizard';
import TaskDetails from './task-details/TaskDetails';
import DownArrow from '../../assets/icons/arrow-down.svg';
import './AppointmentDetails.scss';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';

interface AppointmentParams {
  id?: string | undefined;
}

const AppointmentDetails: React.FC = () => {
  const { id } = useParams<AppointmentParams>();
  const {
    loading,
    tasksLoading,
    detailedAppointment,
    detailedAppointmentTasks,
    modalTask
  } = useSelector((state: RootState) => state.appointment);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { modalVisible } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const refresherRef = useRef<HTMLIonRefresherElement>(null);
  const dispatch = useDispatch();

  const refreshAppointment = () => {
    dispatch(removeDetailedAppointment());
  };

  const rescheduleAppointment = () => {
    dispatch(resetAppointmentWizard());
    dispatch(
      showAppointmentWizard({
        type: HKScheduleType.RESCHEDULE,
        id: detailedAppointment?.id,
        name: detailedAppointment?.name,
        home_id: detailedAppointment?.home_id,
        notes: detailedAppointment?.homeowner_notes
      })
    );
  };

  useEffect(() => {
    if (!loading && !tasksLoading && !!refresherRef) {
      refresherRef.current?.complete();
    }
  }, [dispatch, loading, tasksLoading, refresherRef]);

  useEffect(() => {
    if (!detailedAppointment && !!currentHome) {
      dispatch(fetchAppointmentDetails(currentHome.id, Number(id)));
    }
  }, [dispatch, detailedAppointment, currentHome, id]);

  useEffect(() => {
    if (!detailedAppointmentTasks && !!currentHome) {
      dispatch(fetchAppointmentTaskList(currentHome.id, Number(id)));
    }
  }, [dispatch, detailedAppointmentTasks, currentHome, id]);

  function DesktopAppointmentDetailsView() {
    return (
      <IonPage>
        <DesktopNavigation>{/* BREADCRUMBS HERE */}</DesktopNavigation>
        <IonContent>
          <div className="hk-desktop-appointment-details">
            {!loading && !!detailedAppointment && (
              <div className="hk-desktop-appointment-details-header">
                <ServicePersonCard
                  person={detailedAppointment?.worker}
                  time={formatAppointmentDisplayTimeSlot(
                    detailedAppointment,
                    currentHome?.market_timezone || ''
                  )}
                  date={formatTimezoneDate(
                    detailedAppointment?.scheduled || '',
                    DATE_FORMAT_WEEKDAY_DATE,
                    currentHome?.market_timezone || ''
                  )}
                  completed={!!detailedAppointment?.finished}
                  rescheduleClicked={rescheduleAppointment}
                />
                <h6 className="hk-desktop-appointment-details-header-description ion-padding">
                  {detailedAppointment?.description}
                </h6>
              </div>
            )}
            {!loading && !!detailedAppointmentTasks && (
              <IonList className="hk-desktop-appointment-details-tasks">
                {detailedAppointmentTasks?.map((task) => {
                  return (
                    <TaskItem
                      key={`task-${task.id}`}
                      task={task}
                      timezone={currentHome?.market_timezone || ''}
                    />
                  );
                })}
              </IonList>
            )}
            {(loading || tasksLoading) && <LoadingSpinner />}
          </div>
        </IonContent>
        <IonModal
          cssClass="hk-appointment-details-modal"
          onDidDismiss={() => dispatch(setModalTask(null))}
          isOpen={!!modalTask}
        >
          <TaskDetails />
        </IonModal>
        <IonModal
          cssClass="hk-appointment-wizard-modal"
          isOpen={modalVisible}
          onDidDismiss={() => {
            dispatch(hideAppointmentModal());
          }}
        >
          <AppointmentWizard />
        </IonModal>
      </IonPage>
    );
  }

  function MobileAppointmentDetailsView() {
    return (
      <IonPage>
        <BackButtonHeader title={detailedAppointment?.name} />
        <IonContent className="hk-appointment-details ion-padding-vertical">
          <IonRefresher
            className="hk-refresher ion-padding"
            ref={refresherRef}
            slot="fixed"
            pullFactor={0.6}
            pullMin={100}
            onIonRefresh={refreshAppointment}
          >
            <IonRefresherContent
              pullingIcon={DownArrow}
              pullingText="Pull to refresh"
              refreshingText="Refreshing..."
            />
          </IonRefresher>
          {!loading && !!detailedAppointment && (
            <div className="hk-appointment-details-header ion-margin-horizontal">
              <ServicePersonCard
                person={detailedAppointment?.worker}
                time={formatAppointmentDisplayTimeSlot(
                  detailedAppointment,
                  currentHome?.market_timezone || ''
                )}
                date={formatTimezoneDate(
                  detailedAppointment?.scheduled || '',
                  DATE_FORMAT_WEEKDAY_DATE,
                  currentHome?.market_timezone || ''
                )}
                completed={!!detailedAppointment?.finished}
                rescheduleClicked={rescheduleAppointment}
              />
              <h6 className="hk-appointment-details-header-description ion-padding">
                {detailedAppointment?.description}
              </h6>
            </div>
          )}
          {!loading && !!detailedAppointmentTasks && (
            <IonList className="hk-appointment-details-tasks">
              {detailedAppointmentTasks?.map((task) => {
                return (
                  <TaskItem
                    key={`task-${task.id}`}
                    task={task}
                    timezone={currentHome?.market_timezone || ''}
                  />
                );
              })}
            </IonList>
          )}
          {(loading || tasksLoading) && <LoadingSpinner />}
        </IonContent>
        <IonModal isOpen={!!modalTask}>
          <TaskDetails />
        </IonModal>
        <IonModal isOpen={modalVisible}>
          <AppointmentWizard />
        </IonModal>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopAppointmentDetailsView()
        : MobileAppointmentDetailsView()}
    </>
  );
};

export default AppointmentDetails;
