import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatforms } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { App, AppState } from '@capacitor/app';
import { RootState } from '../store';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema
} from '@capacitor/push-notifications';
import {
  HKNotificationType,
  Notification,
  setDeviceInfo,
  setIsDesktopWidth,
  setPlatformType,
  setVersion
} from './PlatformSlice';
import {
  apiMinorVersionGreaterThanExpected,
  determinePlatformType,
  gaScreenView,
  validateOpenedURL
} from '../util';
import { HKPlatform } from '../constants';
import { displayToast } from './AlertActions';
import { useLocation } from 'react-router';
import { fetchApiVersion } from './PlatformActions';
import { resetAppointmentWizard } from '../../features/appointments/wizard/AppointmentWizardSlice';

const PlatformListener: React.FC = ({ children }) => {
  const { pushNotificationToken, version, apiVersion, isDesktopWidth } =
    useSelector((state: RootState) => state.platform);
  const { modalVisible } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { REACT_APP_VERSION } = process.env;
  let dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();

  const handlePushNotificationAction = (notification: Notification) => {
    const data = notification.data;
    switch (data.hk_type) {
      case HKNotificationType.CatalogReminder:
        history.push(`/initial`);
        break;
      case HKNotificationType.AppointmentReminder:
        history.push(`/appointment/${data.hk_id}`);
        break;
    }
  };

  const getDeviceInfo = async () => {
    const info = await Device.getInfo();
    dispatch(setDeviceInfo(info));
  };

  // track page views
  useEffect(() => {
    gaScreenView(location.pathname);
  }, [location]);

  // set the app display version
  useEffect(() => {
    if (!version) {
      const parts = String(REACT_APP_VERSION).split('.');
      dispatch(setVersion(`v ${parts[0]}.${parts[1]}`));
    }
  }, [version]);

  // listen for magic link URLs
  useEffect(() => {
    App.addListener('appUrlOpen', (data: any) => {
      if (!!data.url) {
        const url = validateOpenedURL(data.url);
        if (!!url) {
          history.push(url);
        }
      }
    });
  }, [history]);

  // fetch the API heartbeat version
  useEffect(() => {
    if (!apiVersion) {
      dispatch(fetchApiVersion());
    } else {
      if (apiMinorVersionGreaterThanExpected(apiVersion)) {
        history.push('/update');
      }
    }
  }, [apiVersion]);

  // ios simulator returns ["iphone","ios","cordova","capacitor","mobile","hybrid"] for platforms
  // ios UserAgent browser return ["iphone", "ios", "mobile", "mobileweb"]
  // use "capacitor" as the check to insure it's a native mobile app
  useEffect(() => {
    const platform = determinePlatformType(getPlatforms());
    if (platform !== HKPlatform.DESKTOP) {
      getDeviceInfo().then();
    }
    dispatch(setPlatformType(platform));

    App.addListener('appStateChange', (state: AppState) => {
      if (!state.isActive && modalVisible) {
        dispatch(resetAppointmentWizard());
      }
    });
  }, [modalVisible]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    const handler = () => {
      dispatch(setIsDesktopWidth(mediaQuery.matches));
    };

    if (isDesktopWidth === null) {
      handler();
    }
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', handler);
    } else {
      mediaQuery.addListener(handler);
    }
  }, []);

  useEffect(() => {
    if (
      Capacitor.isPluginAvailable('PushNotifications') &&
      !!pushNotificationToken
    ) {
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          const hkNotification = notification as Notification;
          // console.log(JSON.stringify(hkNotification));
          dispatch(displayToast(hkNotification.body, hkNotification.title));
        }
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          const hkNotification = notification.notification as Notification;
          // console.log(JSON.stringify(hkNotification));
          handlePushNotificationAction(hkNotification);
        }
      );
    }
  }, [pushNotificationToken]);

  return <>{children}</>;
};

export default PlatformListener;
