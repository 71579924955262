import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  removeAuthTokenFromStorage,
  saveAuthTokenInStorage
} from '../../core/util';

interface LoginState {
  authEmail: string | null;
  authIdToken: string | null;
  emailSubmitted: boolean;
  loading: boolean;
  loggedIn: boolean;
  accessToken: string | null;
  error: string | null;
}

export const initialState: LoginState = {
  loading: false,
  authEmail: null,
  authIdToken: null,
  loggedIn: false,
  emailSubmitted: false,
  accessToken: null,
  error: null
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthEmail: (state, action: PayloadAction<string>) => {
      state.authEmail = action.payload;
    },
    setAuthIdToken: (state, action: PayloadAction<string>) => {
      state.authIdToken = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    requestTokenForFirebaseUserStart: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = true;
    },
    requestTokenForFirebaseUserSuccess: (state, action: PayloadAction<any>) => {
      state.accessToken = action.payload.access_token;
      state.loading = false;
      state.loggedIn = true;
      saveAuthTokenInStorage(action.payload.access_token).then();
    },
    requestTokenForFirebaseUserError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state) => {
      removeAuthTokenFromStorage().then();
      state.authEmail = null;
      state.authIdToken = null;
      state.emailSubmitted = false;
      state.accessToken = null;
      state.loggedIn = false;
      state.error = null;
    }
  }
});

export const {
  setAuthEmail,
  setAuthIdToken,
  setError,
  requestTokenForFirebaseUserStart,
  requestTokenForFirebaseUserSuccess,
  requestTokenForFirebaseUserError,
  logout
} = loginSlice.actions;

export default loginSlice.reducer;
