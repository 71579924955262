import React from 'react';
import { IonList, IonSpinner } from '@ionic/react';
import EmptyList from '../../components/empty/EmptyList';
import EmptyBox from '../../assets/illustrations/empty-box.svg';

type LoadingIonListProps = {
  label: string;
  items?: any[] | null;
  loading: boolean;
  emptyImage?: string;
  className?: string;
};

const LoadingIonList: React.FC<LoadingIonListProps> = ({
  loading,
  label = 'Items',
  items,
  emptyImage = EmptyBox,
  className = '',
  children,
  ...rest
}) => {
  return loading ? (
    <div className="vertical-align-center hk-loading-list-spinner">
      <IonSpinner name="dots" color="grey" />
    </div>
  ) : !!items && items.length ? (
    <IonList {...rest} className={className}>
      {children}
    </IonList>
  ) : (
    <EmptyList image={emptyImage} label={label} />
  );
};

export default LoadingIonList;
