import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { Redirect } from 'react-router';
import { loadTutorialViewedFromStorage } from '../../core/util';
import {
  fetchZendeskToken,
  requestUserInfo
} from '../../core/services/UserActions';
import { CatalogStatus } from '../../core/constants';
import { setScheduleHomeId } from '../appointments/wizard/AppointmentWizardSlice';
import LoadingSpinner, {
  LoadingMode
} from '../../components/loading/LoadingSpinner';

const Checkpoint: React.FC = () => {
  const { accessToken } = useSelector((state: RootState) => state.login);
  const { homes } = useSelector((state: RootState) => state.home);
  const { name, zendesk_token_url } = useSelector(
    (state: RootState) => state.user
  );
  const [route, setRoute] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!homes && homes.length > 0) {
      let initial_route = '/dashboard';
      let tutorialViewed = false;
      loadTutorialViewedFromStorage().then((viewed) => {
        tutorialViewed = viewed;
      });
      homes.forEach((home) => {
        if (
          home.catalog_status === undefined ||
          home.catalog_status === CatalogStatus.NOT_SCHEDULED
        ) {
          dispatch(setScheduleHomeId(home.id));
          initial_route = tutorialViewed ? '/initial' : '/tutorial';
        }
      });
      setRoute(initial_route);
    }
  }, [dispatch, homes]);

  useEffect(() => {
    if (!name) {
      dispatch(requestUserInfo());
    }
  }, [dispatch, name]);

  useEffect(() => {
    if (!accessToken) {
      setRoute('/login');
    }
  }, [accessToken]);

  useEffect(() => {
    if (!zendesk_token_url) {
      dispatch(fetchZendeskToken());
    }
  }, [dispatch, zendesk_token_url]);

  return !!route ? (
    <Redirect to={route} />
  ) : (
    <LoadingSpinner mode={LoadingMode.Logo} />
  );
};

export default Checkpoint;
