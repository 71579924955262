import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  IonModal,
  IonButton,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons
} from '@ionic/react';
// import { resetDetails } from 'app/features/details/ItemDetailSlice';
// import { resetDocumentDetails } from 'app/features/details/DocumentDetailSlice';

interface DrawerProps {
  showCloseButton?: boolean;
  title?: string;
}

const Drawer: React.FC<DrawerProps> = ({
  showCloseButton = true,
  title,
  children
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    // dispatch(resetDetails());
    // dispatch(resetDocumentDetails());
    history.goBack();
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={showCloseButton}
      cssClass="homekeep-modal"
    >
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              {showCloseButton ? (
                <IonButton onClick={() => closeModal()}>Close</IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonPage>
    </IonModal>
  );
};

export default Drawer;
