import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { requestProductGroup } from './InventoryActions';
import { IonContent, IonModal, IonPage, IonRow } from '@ionic/react';
import SearchBar, { filterItems } from '../../components/searchbar/SearchBar';
import { useHistory, useParams } from 'react-router';
import InventoryDetail from './InventoryDetail';
import BackButtonHeader from '../../core/layout/BackButtonHeader';
import ListCard from '../../components/cards/list/ListCard';
import {
  resetItemDetails,
  showItemDetails
} from 'features/inventory/InventoryDetailSlice';
import { ProductGroup } from './InventorySlice';
import { InventoryItem } from '../../core/types';
import Image from '../../assets/icons/image.svg';
import LoadingIonList from '../../core/layout/LoadingIonList';
import './InventoryList.scss';
import { HKPlatform } from 'core/constants';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';

interface ItemParams {
  id?: string | undefined;
}

const InventoryList: React.FC = () => {
  const { id } = useParams<ItemParams>();
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { product_id } = useSelector(
    (state: RootState) => state.inventoryDetails
  );
  const { products, productGroups, loading } = useSelector(
    (state: RootState) => state.inventory
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const [group, setGroup] = useState<ProductGroup>({
    id: 0,
    count: 0,
    group: ''
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const filteredInventory = filterItems(
    products || [],
    [
      'product_template.manufacturer_name',
      'product_template.type',
      'product_template.model',
      'serial'
    ],
    search
  );

  const showModal = (id: number) => {
    dispatch(showItemDetails(id));
  };

  const goToInventoryDetailsPage = (productId: number) => {
    if (id !== undefined) {
      dispatch(resetItemDetails());
      dispatch(showItemDetails(productId));
      history.push(`/inventory/group/${id}/product/${productId}`);
    }
  };

  const imageForItem = (item: InventoryItem): string => {
    if (!!item.image) {
      return item.image;
    }
    if (!!item.product_template.image) {
      return item.product_template.image;
    }
    return Image;
  };

  useEffect(() => {
    if (!products && !!homeId && !!id) {
      dispatch(requestProductGroup(homeId, id));
    }
  }, [dispatch, products, homeId]);

  useEffect(() => {
    if (!!productGroups && !group.count) {
      const g = productGroups?.find((group) => group.id === Number(id));
      if (!!g) {
        setGroup(g);
      }
    }
  }, [productGroups, id]);

  function DesktopInventoryListView() {
    return (
      <IonPage>
        <DesktopNavigation>
          <IonRow className="hk-desktop-inventory-list-search">
            <SearchBar
              value={search}
              onSearchChange={(e) => setSearch(e)}
            ></SearchBar>
          </IonRow>
        </DesktopNavigation>
        <IonContent>
          <LoadingIonList
            loading={loading}
            label={group.group}
            items={filteredInventory}
            className="hk-desktop-inventory-list"
          >
            {filteredInventory.map((product: any, i: any) => {
              const { id, serial, product_template } = product;
              const image = imageForItem(product);
              const css =
                image.indexOf('/static/media') === -1 ? '' : 'no-image';
              return (
                <ListCard
                  className={css}
                  key={`item-${i}`}
                  title={
                    product_template.manufacturer_name +
                    ' - ' +
                    product_template.type
                  }
                  descriptionTitleOne="Model"
                  descriptionOne={'#' + product_template.model}
                  descriptionTitleTwo="Serial"
                  descriptionTwo={'#' + serial}
                  image={image}
                  onClick={() => {
                    goToInventoryDetailsPage(id);
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  function MobileInventoryListView() {
    return (
      <IonPage>
        <BackButtonHeader title={group.group} />
        {!!products ? (
          <IonContent fullscreen>
            <SearchBar
              value={search}
              onSearchChange={(e) => setSearch(e)}
            ></SearchBar>
            <LoadingIonList
              label={group.group}
              loading={loading}
              items={filteredInventory}
              className="hk-inventory-list ion-margin-horizontal"
            >
              {filteredInventory.map((product: any, i: any) => {
                const { id, serial, product_template } = product;
                const image = imageForItem(product);
                const css =
                  image.indexOf('/static/media') === -1 ? '' : 'no-image';
                return (
                  <ListCard
                    className={css}
                    key={`item-${i}`}
                    title={
                      product_template.manufacturer_name +
                      ' - ' +
                      product_template.type
                    }
                    descriptionOne={'Model #' + product_template.model}
                    descriptionTwo={'Serial #' + serial}
                    image={image}
                    onClick={() => {
                      showModal(id);
                    }}
                  />
                );
              })}
            </LoadingIonList>
          </IonContent>
        ) : null}
        <IonModal isOpen={!!product_id}>
          <InventoryDetail />
        </IonModal>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInventoryListView()
        : MobileInventoryListView()}
    </>
  );
};

export default InventoryList;
