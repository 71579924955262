import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isSameMonth, parseISO } from 'date-fns';
import {
  Appointment,
  AppointmentProposal,
  AppointmentProposalGroup
} from '../../../core/types';
import { HKScheduleType } from '../../../core/constants';

export interface DateConfig {
  monthIndex?: number;
  monthLabels?: string[];
  monthList?: string[];
  dateList?: string[];
  dateListForMonth?: string[];
}

export interface HKScheduleConfig {
  type: HKScheduleType;
  id?: number;
  name?: string;
  home_id?: number;
  visit_hash?: string;
  notes?: string;
}

interface AppointmentWizardState {
  slideNum: number;
  scheduleType: number;
  scheduleHomeId: number;
  appointmentId: number | null;
  upcomingVisitHash: string | null;
  appointmentName: string | null;
  appointmentNote?: string;
  dateConfig: DateConfig | null;
  proposalGroups: AppointmentProposalGroup[] | null;
  proposalHash: string;
  appointment: Appointment | null;
  modalVisible: boolean;
  error: string;
}

export const initialState: AppointmentWizardState = {
  slideNum: 0,
  scheduleType: -1,
  scheduleHomeId: -1,
  appointmentId: -1,
  upcomingVisitHash: null,
  appointmentName: null,
  appointmentNote: '',
  dateConfig: null,
  proposalGroups: null,
  proposalHash: '',
  appointment: null,
  modalVisible: false,
  error: ''
};

export const appointmentWizardSlice = createSlice({
  name: 'appointmentWizard',
  initialState,
  reducers: {
    showAppointmentWizard: (state, action: PayloadAction<HKScheduleConfig>) => {
      const { type, id, home_id, name, notes, visit_hash } = action.payload;
      if (!!id) {
        state.appointmentId = id;
      }
      if (!!visit_hash) {
        state.upcomingVisitHash = visit_hash;
      }
      if (!!home_id) {
        state.scheduleHomeId = home_id;
      }
      if (!!name) {
        state.appointmentName = name;
      }
      if (!!notes) {
        state.appointmentNote = notes;
      }
      state.scheduleType = type;
      state.modalVisible = true;
    },
    hideAppointmentModal: (state) => {
      state.modalVisible = false;
    },
    setDateConfig: (state, action: PayloadAction<DateConfig>) => {
      state.dateConfig = action.payload;
    },
    setSlideNum: (state, action: PayloadAction<number>) => {
      state.slideNum = action.payload;
    },
    setScheduleHomeId: (state, action: PayloadAction<any>) => {
      state.scheduleHomeId = action.payload;
    },
    setProposalHash: (state, action: PayloadAction<any>) => {
      state.proposalHash = action.payload;
    },
    setAppointmentNote: (state, action: PayloadAction<any>) => {
      state.appointmentNote = action.payload;
    },
    fetchProposalsSuccess: (state, action: PayloadAction<any>) => {
      const { current_date, available_dates, items } = action.payload;
      if (!state.dateConfig) {
        state.dateConfig = initializeDateConfig({ dateList: available_dates });
        state.proposalGroups = [];
      }
      if (!!state.proposalGroups) {
        state.proposalGroups.push({
          date: current_date,
          proposals: sortProposals(items)
        });
      }
    },
    fetchProposalsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setProposalGroups: (state, action: PayloadAction<any>) => {
      state.proposalGroups = action.payload;
    },
    resetProposals: (state) => {
      state.proposalGroups = [];
    },
    confirmAppointmentSuccess: (state, action: PayloadAction<Appointment>) => {
      state.appointment = action.payload;
    },
    confirmAppointmentError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    resetAppointmentWizard: (state) => {
      state.slideNum = 0;
      state.modalVisible = false;
      state.scheduleType = -1;
      state.appointmentId = null;
      state.upcomingVisitHash = null;
      state.appointmentName = null;
      state.appointmentNote = '';
      state.dateConfig = null;
      state.proposalHash = '';
      state.proposalGroups = null;
      state.appointment = null;
      state.error = '';
    }
  }
});

export function initializeDateConfig(config: DateConfig) {
  if (!config.monthList) {
    config.monthList = [];
  }
  if (!config.dateList) {
    config.dateList = [];
  }
  config.monthIndex = 0;
  buildMonthList(config);
  filterDaysForSelectedMonth(config);
  return config;
}

function buildMonthList(config: DateConfig) {
  if (!!config.dateList && !!config.monthList) {
    config.dateList.forEach((date) => {
      if (!includesSameMonth(date, config.monthList || [])) {
        config.monthList?.push(date);
      }
    });
  }
}

function includesSameMonth(date: string, list: string[]) {
  const dateObj = parseISO(date);
  let found = false;
  if (!!list && list.length) {
    list.forEach((d) => {
      if (isSameMonth(parseISO(d), dateObj)) {
        found = true;
      }
    });
  }
  return found;
}

export function filterDaysForSelectedMonth(config: DateConfig) {
  if (!!config.dateList && config.monthList) {
    const selectedMonth = parseISO(config.monthList[config.monthIndex || 0]);
    config.dateListForMonth = config.dateList?.filter((date) => {
      return isSameMonth(selectedMonth, parseISO(date));
    });
  }
}

function sortProposals(
  proposals: AppointmentProposal[]
): AppointmentProposal[] {
  return proposals.sort(function (a, b) {
    return (
      new Date(a.schedule_start).getTime() -
      new Date(b.schedule_start).getTime()
    );
  });
}

export const {
  showAppointmentWizard,
  hideAppointmentModal,
  setDateConfig,
  setSlideNum,
  setScheduleHomeId,
  fetchProposalsSuccess,
  fetchProposalsError,
  resetProposals,
  setProposalGroups,
  setProposalHash,
  setAppointmentNote,
  confirmAppointmentSuccess,
  confirmAppointmentError,
  resetAppointmentWizard
} = appointmentWizardSlice.actions;

export default appointmentWizardSlice.reducer;
