import React from 'react';
import EmptyBox from '../../assets/illustrations/empty-box.svg';
import './EmptyList.scss';

type EmptyListProps = {
  label: string;
  image?: string;
};

const EmptyList: React.FC<EmptyListProps> = ({
  label = 'Items',
  image = EmptyBox,
  ...rest
}) => {
  return (
    <section className="hk-empty-list" {...rest}>
      <img src={image} alt="No items" />
      <h2>No {label}</h2>
    </section>
  );
};

export default EmptyList;
