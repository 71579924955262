import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import { fetchDocuments } from './DocumentActions';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import SearchBar, { filterItems } from '../../components/searchbar/SearchBar';
import { useParams } from 'react-router';
import ListCard from '../../components/cards/list/ListCard';
import BackButtonHeader from '../../core/layout/BackButtonHeader';
import NoImage from '../../assets/illustrations/empty-box.svg';
import { HKDocumentType } from './DocumentDetailSlice';
import LoadingIonList from '../../core/layout/LoadingIonList';
import { viewDocument } from '../../core/util';
import './DocumentList.scss';
import { HKPlatform } from 'core/constants';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';

interface DocumentParams {
  type?: string | undefined;
}

const DocumentList: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const { type } = useParams<DocumentParams>();
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { documents } = useSelector((state: RootState) => state.documents);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const showModal = (id: number) => {
    // dispatch(showDocumentDetail(id));
  };

  useEffect(() => {
    if (!documents && !!homeId && !!type) {
      dispatch(fetchDocuments(homeId, type));
    }
  }, [dispatch, documents, homeId, type]);

  const filteredDocuments = filterItems(
    documents || [],
    [
      'product_data.manufacturer_name',
      'product_data.model',
      'product_data.type_name'
    ],
    search
  );

  function DesktopDocumentsView() {
    return (
      <IonPage>
        <DesktopNavigation>
          <IonRow className="hk-desktop-documents-group-search">
            <SearchBar
              value={search}
              onSearchChange={(e) => setSearch(e)}
            ></SearchBar>
          </IonRow>
        </DesktopNavigation>
        <IonContent>
          <LoadingIonList
            loading={loading}
            items={filteredDocuments}
            className={`hk-desktop-document-list ${type}`}
            label={type === HKDocumentType.Manuals ? 'Manuals' : 'Photos'}
          >
            {filteredDocuments.map((document: any, i: any) => {
              const { name, file_url, product_data, thumbnail_url } = document;
              const manufacturer = !!product_data?.manufacturer_name
                ? product_data?.manufacturer_name
                : name;
              const model = !!product_data?.model
                ? `${product_data?.model}`
                : '';
              const category = !!product_data?.type_name
                ? product_data?.type_name
                : '';
              return type === HKDocumentType.Manuals ? (
                <ListCard
                  key={`item-${i}`}
                  title={manufacturer}
                  descriptionOne={model}
                  descriptionTwo={category}
                  image={
                    !!product_data?.manufacturer_image
                      ? product_data.manufacturer_image
                      : NoImage
                  }
                  onClick={() => {
                    viewDocument(file_url, platformType);
                  }}
                />
              ) : (
                <div
                  key={`item-${i}`}
                  className="hk-desktop-document-list-img"
                  style={{
                    backgroundImage: `url(${
                      thumbnail_url ? thumbnail_url : file_url
                    })`
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  function MobileDocumentsView() {
    return (
      <IonPage>
        <BackButtonHeader
          title={type === HKDocumentType.Manuals ? 'Manuals' : 'Photos'}
        />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            loading={loading}
            items={filteredDocuments}
            className={`hk-document-list ion-margin-horizontal ${type}`}
            label={type === HKDocumentType.Manuals ? 'Manuals' : 'Photos'}
          >
            {filteredDocuments.map((document: any, i: any) => {
              const { name, file_url, product_data, thumbnail_url } = document;
              const manufacturer = !!product_data?.manufacturer_name
                ? product_data?.manufacturer_name
                : name;
              const model = !!product_data?.model
                ? `Model #${product_data?.model}`
                : '';
              const category = !!product_data?.type_name
                ? product_data?.type_name
                : '';
              return type === HKDocumentType.Manuals ? (
                <ListCard
                  key={`item-${i}`}
                  title={manufacturer}
                  descriptionOne={model}
                  descriptionTwo={category}
                  image={
                    !!product_data?.manufacturer_image
                      ? product_data.manufacturer_image
                      : NoImage
                  }
                  onClick={() => {
                    viewDocument(file_url, platformType);
                  }}
                />
              ) : (
                <div
                  key={`item-${i}`}
                  className="hk-document-list-img"
                  style={{
                    backgroundImage: `url(${
                      thumbnail_url ? thumbnail_url : file_url
                    })`
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsView()
        : MobileDocumentsView()}
    </>
  );
};

export default DocumentList;
