import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import { hideAlert } from 'core/services/AlertSlice';
import { IonToast } from '@ionic/react';
import { DANGER, PRIMARY } from '../constants';
import Complete from '../../assets/icons/complete.svg';
import Error from '../../assets/icons/error.svg';
import './AlertContainer.scss';

const AlertContainer: React.FC<any> = ({ ...rest }) => {
  const { isOpen, intent, message } = useSelector(
    (state: RootState) => state.alert
  );
  const dispatch = useDispatch();

  let buttons: object[] = [];
  switch (intent) {
    case DANGER:
      buttons = [
        { side: 'start', color: intent, icon: Error, cssClass: 'hk-toast-icon' }
      ];
      break;
    case PRIMARY:
      buttons = [
        {
          side: 'start',
          color: intent,
          icon: Complete,
          cssClass: 'hk-toast-icon'
        }
      ];
      break;
    default:
      buttons = [];
  }

  return isOpen ? (
    <IonToast
      {...rest}
      className={`hk-toast ${intent}`}
      isOpen={isOpen}
      message={message}
      position="bottom"
      translucent={true}
      duration={4000}
      buttons={buttons}
      onDidDismiss={() => {
        dispatch(hideAlert());
      }}
    ></IonToast>
  ) : null;
};

export default AlertContainer;
