import { Appointment, StatusTag, Task } from '../../core/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppointmentState {
  loading: boolean;
  tasksLoading: boolean;
  detailedAppointment: Appointment | null;
  detailedAppointmentTasks: Task[] | null;
  statusTags: StatusTag[] | null;
  modalTask: Task | null;
  error: string | null;
}

export const initialState: AppointmentState = {
  loading: true,
  tasksLoading: false,
  detailedAppointment: null,
  detailedAppointmentTasks: null,
  statusTags: null,
  modalTask: null,
  error: null
};

export const appointmentDetailsSlice = createSlice({
  name: 'appointmentDetails',
  initialState,
  reducers: {
    fetchAppointmentDetailsStart: (state) => {
      state.loading = true;
    },
    fetchAppointmentDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.detailedAppointment = action.payload;
      state.loading = false;
    },
    fetchAppointmentDetailsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.detailedAppointment = null;
      state.loading = false;
    },
    fetchAppointmentTaskListStart: (state) => {
      state.tasksLoading = true;
    },
    fetchAppointmentTaskListSuccess: (state, action: PayloadAction<any>) => {
      state.detailedAppointmentTasks = action.payload;
      state.tasksLoading = false;
    },
    fetchAppointmentTaskListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.detailedAppointmentTasks = null;
      state.tasksLoading = false;
    },
    fetchStatusTagListSuccess: (state, action: PayloadAction<any>) => {
      state.statusTags = action.payload;
    },
    fetchStatusTagListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.statusTags = null;
    },
    removeDetailedAppointment: (state) => {
      state.detailedAppointment = null;
      state.detailedAppointmentTasks = null;
    },
    setModalTask: (state, action: PayloadAction<Task | null>) => {
      state.modalTask = action.payload;
    }
  }
});

export const {
  fetchAppointmentDetailsStart,
  fetchAppointmentDetailsSuccess,
  fetchAppointmentDetailsError,
  fetchAppointmentTaskListStart,
  fetchAppointmentTaskListSuccess,
  fetchAppointmentTaskListError,
  fetchStatusTagListSuccess,
  fetchStatusTagListError,
  removeDetailedAppointment,
  setModalTask
} = appointmentDetailsSlice.actions;

export default appointmentDetailsSlice.reducer;
