import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isOpen: boolean;
}

export const initialState: InitialState = {
  isOpen: false
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showSlideOutMenu: (state) => {
      state.isOpen = true;
    },
    hideSlideOutMenu: (state) => {
      state.isOpen = false;
    }
  }
});

export const { showSlideOutMenu, hideSlideOutMenu } = menuSlice.actions;

export default menuSlice.reducer;
