import React from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { chevronForwardOutline } from 'ionicons/icons';
import {
  formatTaskFinishedDate,
  highestStatusTagLevel
} from '../../../core/util';
import { setModalTask } from '../../../features/appointments/AppointmentDetailsSlice';
import { StatusTag, Task } from 'core/types';
import Clock from '../../../assets/icons/clock.svg';
import Pending from '../../../assets/icons/pending.svg';
import './TaskItem.scss';
import { RootState } from 'core/store';
import { HKPlatform } from 'core/constants';
import ArrowRight from '../../../assets/icons/arrow-right.svg';

type TaskItemProps = {
  task: Task;
  timezone: string;
};

const TaskItem: React.FC<TaskItemProps> = ({ task, timezone, ...rest }) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const dispatch = useDispatch();

  const viewTaskDetails = () => {
    dispatch(setModalTask(task));
  };

  const statusTagLevelClass = (tags?: StatusTag[]): string => {
    const highLevel = highestStatusTagLevel(tags);
    return highLevel > 0
      ? `level-${highLevel}`
      : task.finished
      ? 'finished'
      : '';
  };

  function DesktopTaskItemView() {
    return (
      <div
        className={`hk-desktop-task ${statusTagLevelClass(task.statuses)}`}
        onClick={viewTaskDetails}
        {...rest}
      >
        <div className="hk-desktop-task-content">
          <h3 className="hk-desktop-task-content-name">{task.name}</h3>
          {!!task.statuses && (
            <div className="hk-status-tag-list">
              {!!task.finished ? (
                <>
                  <div className={`hk-status-tag-list-item level-0`}>
                    complete
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`hk-status-tag-list-item  hk-status-pill-incomplete`}
                  >
                    incomplete
                  </div>
                </>
              )}
              {task.statuses?.map((tag, index) => {
                return (
                  <div
                    className={`hk-status-tag-list-item level-${tag.level}`}
                    key={`status-tag-${task.id}-${index}`}
                  >
                    {tag.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <IonIcon
          slot="end"
          icon={ArrowRight}
          className="hk-desktop-task-icon"
        ></IonIcon>
      </div>
    );
  }

  function MobileTaskItemView() {
    return (
      <div
        className={`hk-task ion-margin-horizontal ${statusTagLevelClass(
          task.statuses
        )}`}
        onClick={viewTaskDetails}
        {...rest}
      >
        <div className="hk-task-content">
          <h3 className="hk-task-content-name">{task.name}</h3>
          {!!task.statuses && (
            <div className="hk-status-tag-list">
              {task.statuses?.map((tag, index) => {
                return (
                  <div
                    className={`hk-status-tag-list-item level-${tag.level}`}
                    key={`status-tag-${task.id}-${index}`}
                  >
                    {tag.label}
                  </div>
                );
              })}
            </div>
          )}
          <div className="hk-task-content-status">
            {!!task.finished ? (
              <>
                <IonIcon color="medium" icon={Clock} />
                <IonText color="medium">
                  {formatTaskFinishedDate(task.finished, timezone)}
                </IonText>
              </>
            ) : (
              <>
                <IonIcon color="medium" icon={Pending} />
                <IonText color="medium">Pending</IonText>
              </>
            )}
          </div>
        </div>
        <IonIcon
          color="medium"
          size="large"
          icon={chevronForwardOutline}
          className="hk-task-icon"
        ></IonIcon>
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopTaskItemView()
        : MobileTaskItemView()}
    </>
  );
};

export default TaskItem;
