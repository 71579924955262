import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect, useParams } from 'react-router';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner,
  IonText
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  FirebaseAuthentication,
  GetCurrentUserResult
} from '@tambroseavid/capacitor-firebase-authentication';
import {
  requestTokenForFirebaseUserSuccess,
  setAuthEmail,
  setAuthIdToken,
  setError
} from 'features/login/LoginSlice';
import { loadAuthTokenFromStorage, openSignup } from '../../core/util';
import { requestTokenForFirebaseUser } from './LoginActions';
import Illustration from 'components/Illustrations/Illustration';
import HomeKeepLogo from 'assets/illustrations/hk-logo-dark.svg';
import AppleLogo from 'assets/illustrations/apple-logo.svg';
import GoogleLogo from 'assets/illustrations/google-logo.svg';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX, firebaseConfig, HKPlatform } from '../../core/constants';
import './Login.scss';

interface AuthParams {
  code?: string | undefined;
}

const Login: React.FC = () => {
  const { authEmail, authIdToken, accessToken, loading, error } = useSelector(
    (state: RootState) => state.login
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [showingPasswordLogin, setShowingPasswordLogin] = useState(true);
  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const { code } = useParams<AuthParams>();
  const dispatch = useDispatch();

  firebase.initializeApp(firebaseConfig);

  type FormData = {
    email: string;
    password: string;
  };

  const showSignIn = () => {
    setShowingPasswordLogin(true);
  };

  const cancelAll = () => {
    setShowingPasswordLogin(false);
  };

  const getCurrentUser = async (): Promise<GetCurrentUserResult> => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  };

  const getIdToken = async () => {
    const result = await FirebaseAuthentication.getIdToken();
    return result.token;
  };

  const onFormSubmit = handleSubmit(({ email, password }) => {
    if (
      errors.email === undefined &&
      showingPasswordLogin &&
      errors.password === undefined
    ) {
      signInWithPassword(email, password).then();
    }
  });

  const signInWithPassword = async (email: string, password: string) => {
    dispatch(setError(null));
    await FirebaseAuthentication.signInWithPassword({
      email: email,
      password: password
    }).catch((error) => {
      dispatch(setError('Sign in failed. Please try again.'));
    });
    getCurrentUser().then((result) => {
      if (!!result.user) {
        getIdTokenForEmail(result.user.email!);
      }
    });
  };

  const signInWithApple = async () => {
    await FirebaseAuthentication.signInWithApple();
    getCurrentUser().then((result) => {
      getIdTokenForEmail(result.user?.email!);
    });
  };

  const signInWithGoogle = async () => {
    await FirebaseAuthentication.signInWithGoogle();
    getCurrentUser().then((result) => {
      getIdTokenForEmail(result.user?.email!);
    });
  };

  const getIdTokenForEmail = (email: string) => {
    dispatch(setAuthEmail(email));
    getIdToken().then((token) => {
      dispatch(setAuthIdToken(token));
    });
  };

  useEffect(() => {
    if (!!authEmail && !!authIdToken) {
      dispatch(requestTokenForFirebaseUser(authIdToken));
    }
  }, [dispatch, authEmail, authIdToken]);

  useEffect(() => {
    if (!accessToken) {
      loadAuthTokenFromStorage().then((token) => {
        if (token) {
          dispatch(requestTokenForFirebaseUserSuccess({ access_token: token }));
        }
      });
    }
  }, [dispatch, accessToken]);

  function iOSButtons(buttonSize: 'small' | 'default' | 'large' | undefined) {
    return (
      <div>
        <IonButton
          className="hk-auth-button ion-text-left ion-margin-vertical"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithApple}
        >
          <IonIcon
            src={AppleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Apple
        </IonButton>
        <IonButton
          className="hk-auth-button ion-text-left"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithGoogle}
        >
          <IonIcon
            src={GoogleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Google
        </IonButton>
      </div>
    );
  }

  function androidButtons(
    buttonSize: 'small' | 'default' | 'large' | undefined
  ) {
    return (
      <div>
        <IonButton
          className="hk-auth-button ion-text-left ion-margin-vertical"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithGoogle}
        >
          <IonIcon
            src={GoogleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Google
        </IonButton>
        <IonButton
          className="hk-auth-button ion-text-left"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithApple}
        >
          <IonIcon
            src={AppleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Apple
        </IonButton>
      </div>
    );
  }

  function DesktopLoginView() {
    return (
      <IonPage>
        <IonContent className="login-view-desktop">
          <div className="login-view-desktop-container">
            <Illustration image={HomeKeepLogo} description="HomeKeep" />
            <div className="login-content-container">
              <div className="login-content">
                <h3 className="login-title ion-text-center">
                  <b>Stress-Free Home Upkeep</b>
                </h3>
                <h6 className="login-subheader ion-padding-horizontal ion-text-center">
                  Preventative care and regular maintenance for every inch of
                  your home.
                </h6>
                {!showingPasswordLogin && (
                  <section className="login-view-provider-list ion-padding-vertical ion-margin-vertical">
                    <IonButton
                      className="hk-auth-button"
                      color="primary"
                      size="default"
                      strong={true}
                      onClick={showSignIn}
                    >
                      Sign In with Email
                    </IonButton>
                    <div className="login-view-provider-list-separator">
                      <span>or</span>
                      <hr />
                    </div>
                    {platformType !== HKPlatform.ANDROID &&
                      iOSButtons('default')}
                    {platformType === HKPlatform.ANDROID &&
                      androidButtons('default')}
                  </section>
                )}
                {showingPasswordLogin && (
                  <section className="login-view-password-form ion-padding-vertical ion-margin-vertical">
                    <form className="hk-form" onSubmit={onFormSubmit}>
                      <div className="hk-form-row">
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          className="login-field"
                          autoCorrect="off"
                          autoCapitalize="none"
                          color="medium"
                          ref={register({
                            required: true,
                            minLength: 4,
                            pattern: EMAIL_REGEX
                          })}
                        />
                      </div>
                      <div className="hk-form-row">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="login-field"
                          autoCorrect="off"
                          autoCapitalize="none"
                          color="medium"
                          ref={register({
                            required: 'You must specify a password.'
                          })}
                        />
                      </div>
                      <div className="hk-form-actions">
                        <IonButton
                          className="hk-auth-button ion-margin-vertical"
                          color="primary"
                          size="default"
                          strong={true}
                          type="submit"
                        >
                          Sign In
                        </IonButton>
                        <IonButton
                          className="hk-auth-button ion-text-left"
                          color="white"
                          size="default"
                          strong={true}
                          onClick={cancelAll}
                        >
                          Cancel
                        </IonButton>
                        {showingPasswordLogin && (
                          <div className="hk-form-actions-forgot-password ion-margin-vertical ion-text-center">
                            <IonRouterLink routerLink="reset">
                              Forgot Password?
                            </IonRouterLink>
                          </div>
                        )}
                      </div>
                      {showingPasswordLogin && errors.email && (
                        <p className="hk-error">
                          Your email appears to be invalid.
                        </p>
                      )}
                      {showingPasswordLogin && errors.password && (
                        <p className="hk-error">{errors.password.message}</p>
                      )}
                      {showingPasswordLogin && error && (
                        <p className="hk-error">{error}</p>
                      )}
                    </form>
                  </section>
                )}
              </div>
            </div>
            {loading && (
              <div className="hk-body-spinner">
                <IonRow className="vertical-align-center">
                  <IonSpinner name="dots" />
                </IonRow>
              </div>
            )}
            {!showingPasswordLogin && (
              <div className="login-view-footer ion-margin-vertical">
                <IonText color="medium">
                  <b>Don't have an Account? </b>
                </IonText>
                <a color="primary" onClick={() => openSignup(platformType)}>
                  <b>Sign up</b>
                </a>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileLoginView() {
    return (
      <IonPage>
        <IonContent
          className="login-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <Illustration image={HomeKeepLogo} description="HomeKeep" />
          <div>
            <h3 className="ion-text-center">
              <b>Stress-Free Home Upkeep</b>
            </h3>
            <h6 className="login-subheader ion-padding-horizontal ion-text-center">
              Preventative care and regular maintenance for every inch of your
              home.
            </h6>
          </div>
          {!showingPasswordLogin && (
            <section className="login-view-provider-list ion-padding-vertical ion-margin-vertical">
              <IonButton
                className="hk-auth-button"
                color="primary"
                size="large"
                strong={true}
                onClick={showSignIn}
              >
                Sign In with Email
              </IonButton>
              <div className="login-view-provider-list-separator">
                <span>or</span>
                <hr />
              </div>
              {platformType !== HKPlatform.ANDROID && iOSButtons('large')}
              {platformType === HKPlatform.ANDROID && androidButtons('large')}
            </section>
          )}
          {showingPasswordLogin && (
            <section className="login-view-password-form ion-padding-vertical ion-margin-vertical">
              <form className="hk-form" onSubmit={onFormSubmit}>
                <div className="hk-form-row">
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="login-field"
                    autoCorrect="off"
                    autoCapitalize="none"
                    color="medium"
                    ref={register({
                      required: true,
                      minLength: 4,
                      pattern: EMAIL_REGEX
                    })}
                  />
                </div>
                <div className="hk-form-row">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="login-field"
                    autoCorrect="off"
                    autoCapitalize="none"
                    color="medium"
                    ref={register({
                      required: 'You must specify a password.'
                    })}
                  />
                </div>
                <div className="hk-form-actions">
                  <IonButton
                    className="hk-auth-button ion-margin-vertical"
                    color="primary"
                    size="large"
                    strong={true}
                    type="submit"
                  >
                    Sign In
                  </IonButton>
                  <IonButton
                    className="hk-auth-button ion-text-left"
                    color="white"
                    size="large"
                    strong={true}
                    onClick={cancelAll}
                  >
                    Cancel
                  </IonButton>
                  {showingPasswordLogin && (
                    <div className="hk-form-actions-forgot-password ion-margin-vertical ion-text-center">
                      <IonRouterLink routerLink="reset">
                        Forgot Password?
                      </IonRouterLink>
                    </div>
                  )}
                </div>
                {showingPasswordLogin && errors.email && (
                  <p className="hk-error">Your email appears to be invalid.</p>
                )}
                {showingPasswordLogin && errors.password && (
                  <p className="hk-error">{errors.password.message}</p>
                )}
                {showingPasswordLogin && error && (
                  <p className="hk-error">{error}</p>
                )}
              </form>
            </section>
          )}
          {loading && (
            <div className="hk-body-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
          {!showingPasswordLogin && (
            <div className="login-view-footer ion-margin-vertical">
              <h6 className="login-view-footer-help ion-padding-horizontal ion-text-center">
                HomeKeep now uses email and password authentication. Customers
                who signed-up with via magic link, please{' '}
                <a color="primary" href="mailto:support@homekeep.com">
                  contact
                </a>{' '}
                the concierge for assistance with your new credentials.
              </h6>
              <IonText color="medium">
                <b>Don't have an Account? </b>
              </IonText>
              <a color="primary" onClick={() => openSignup(platformType)}>
                <b>Sign up</b>
              </a>
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }

  return accessToken ? (
    <Redirect to="/checkpoint" />
  ) : (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
        <DesktopLoginView />
      ) : (
        <MobileLoginView />
      )}
    </>
  );
};

export default Login;
