import { AppThunk, AppDispatch } from 'core/store';
import {
  fetchHomeSuccess,
  registerAllProductsStart,
  registerAllProductsFinish,
  deRegisterAllProductsStart,
  deRegisterAllProductsFinish,
  incrementItemCount,
  fetchProductGroupCountSuccess,
  fetchProductGroupSuccess,
  fetchProductGroupStart
} from './InventorySlice';
import {
  HOME_URL,
  REGISTER_ALL_URL,
  DEREGISTER_ALL_URL,
  ITEM_REGISTER_URL,
  PRODUCT_GROUP_COUNT_URL,
  PRODUCT_GROUP_URL,
  PRODUCT_DETAILS_URL
} from 'core/constants';
import {
  fetchProductDetailsSuccess,
  registrationFinish,
  registrationStart
} from './InventoryDetailSlice';
import { createApiRequest } from 'core/util';
import { displayAlert } from 'core/services/AlertSlice';

export const requestProductGroupCount = (homeId: number) =>
  createApiRequest({
    route: PRODUCT_GROUP_COUNT_URL,
    replace: ['home_id', homeId],
    onSuccess: fetchProductGroupCountSuccess,
    defaultErrorMessage: 'There was a problem loading your products.',
    useGlobalLoader: true
  })();

export const requestProductGroup = (homeId: number, groupId: string) =>
  createApiRequest({
    route: PRODUCT_GROUP_URL,
    replace: [
      ['home_id', homeId],
      ['group_id', groupId]
    ],
    onStart: fetchProductGroupStart,
    onSuccess: fetchProductGroupSuccess,
    defaultErrorMessage: 'There was a problem loading your products.',
    useGlobalLoader: true
  })();

export const fetchProductDetails = (homeId: number, itemId: number) =>
  createApiRequest({
    route: PRODUCT_DETAILS_URL,
    replace: [
      ['home_id', homeId],
      ['item_id', itemId]
    ],
    onSuccess: fetchProductDetailsSuccess,
    defaultErrorMessage: 'There was a problem loading item details.',
    useGlobalLoader: true
  })();

export const requestHome = (homeId: number) =>
  createApiRequest({
    route: HOME_URL,
    replace: ['home_id', homeId],
    onSuccess: fetchHomeSuccess,
    defaultErrorMessage: 'There was a problem loading your home.'
  })();

export const requestInventoryRegistration = (homeId: number) =>
  createApiRequest({
    route: REGISTER_ALL_URL,
    method: 'patch',
    replace: [['home_id', homeId]],
    onStart: registerAllProductsStart,
    onSuccess: registerAllProductsFinish,
    onComplete: completeInventoryRegistration,
    defaultErrorMessage: 'There was a problem registering your inventory.'
  })();

export const requestInventoryDeRegistration = (homeId: number) =>
  createApiRequest({
    route: DEREGISTER_ALL_URL,
    method: 'patch',
    replace: [['home_id', homeId]],
    onStart: deRegisterAllProductsStart,
    onSuccess: deRegisterAllProductsFinish,
    defaultErrorMessage: 'There was a problem de-registering your inventory.'
  })();

export const completeInventoryRegistration =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(
      displayAlert({
        title: 'Thank you for registering!',
        message:
          'Our team will submit the appropriate information to the vendor.'
      })
    );
  };

export const requestProductRegistration = (homeId: number, itemId: number) =>
  createApiRequest({
    route: ITEM_REGISTER_URL,
    method: 'patch',
    replace: [
      ['home_id', homeId],
      ['item_id', itemId]
    ],
    onStart: registrationStart,
    onSuccess: registrationFinish,
    onComplete: completeProductRegistration,
    defaultErrorMessage: 'There was a problem registering your item.'
  })();

export const completeProductRegistration =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(incrementItemCount());
    dispatch(
      displayAlert({
        title: 'Thank you for registering!',
        message:
          'Our team will submit the appropriate information to the vendor.'
      })
    );
  };
