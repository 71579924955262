import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalMode } from '../../features/account/Account';

interface UserState {
  id: number | null;
  name: string;
  email: string;
  mobile_phone: string;
  modal_mode: number;
  zendesk_token: string | null;
  zendesk_token_url: string | null;
  error: string | null;
}

export const initialState: UserState = {
  id: null,
  name: '',
  email: '',
  mobile_phone: '',
  modal_mode: 0,
  zendesk_token: null,
  zendesk_token_url: null,
  error: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      const { id, name, email, mobile_phone } = action.payload;
      state.id = id;
      state.email = email;
      state.name = name;
      state.mobile_phone = mobile_phone;
    },
    resetUserInfo: (state) => {
      state.id = null;
      state.email = '';
      state.name = '';
      state.mobile_phone = '';
    },
    updateUserSuccess: (state, action: PayloadAction<any>) => {
      const { name, email, mobile_phone } = action.payload;
      state.name = name;
      state.email = email;
      state.mobile_phone = mobile_phone;
      state.modal_mode = ModalMode.Hidden;
    },
    updateUserFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    showUserEdit: (state, action: PayloadAction<any>) => {
      state.modal_mode = action.payload;
    },
    resetUserEdit: (state) => {
      state.modal_mode = ModalMode.Hidden;
    },
    fetchZendeskTokenSuccess: (state, action: PayloadAction<any>) => {
      const { url, jwt_token } = action.payload;
      state.zendesk_token = jwt_token;
      state.zendesk_token_url = url;
    },
    fetchZendeskTokenFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    }
  }
});

export const {
  fetchUserFailure,
  setUserInfo,
  resetUserInfo,
  updateUserSuccess,
  updateUserFailure,
  showUserEdit,
  resetUserEdit,
  fetchZendeskTokenSuccess,
  fetchZendeskTokenFailure
} = userSlice.actions;

export default userSlice.reducer;
