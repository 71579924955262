import { createApiRequest } from 'core/util';
import {
  fetchContactListSuccess,
  fetchContactListError
} from './ContactListSlice';
import { CONTACT_LIST_URL } from 'core/constants';

export const requestContactList = (homeId: number) =>
  createApiRequest({
    route: CONTACT_LIST_URL,
    replace: ['home_id', homeId],
    onSuccess: fetchContactListSuccess,
    onError: fetchContactListError,
    defaultErrorMessage: 'There was a problem loading your contacts.',
    useGlobalLoader: true
  })();
