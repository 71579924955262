import { createApiRequest } from 'core/util';
import { HOME_URL } from 'core/constants';
import {
  Home,
  setHomes,
  updateHomeFailure,
  updateHomeSuccess
} from './HomeSlice';
import { resetUserEdit } from './UserSlice';
import { AppDispatch, AppThunk } from '../store';

export const requestHomeInfo = (id: number) =>
  createApiRequest({
    route: HOME_URL,
    replace: ['id', id],
    onSuccess: setHomes,
    defaultErrorMessage: ''
  })();

export const updateHome = (home: Home, homeId: number) =>
  createApiRequest({
    route: HOME_URL,
    replace: ['home_id', homeId],
    method: 'patch',
    body: home,
    onSuccess: updateHomeSuccess,
    onError: updateHomeFailure,
    onComplete: completeHomeUpdate,
    defaultErrorMessage: 'There was a problem updating your home.',
    useGlobalLoader: true
  })();

export const completeHomeUpdate =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(resetUserEdit());
  };
