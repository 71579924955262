import React from 'react';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import MenuIcon from '../../../assets/icons/menu.svg';
import UserIcon from '../../../assets/icons/user.svg';
import { useDispatch, useSelector } from 'react-redux';
import { showSlideOutMenu } from '../../services/MenuSlice';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import './MenuHeader.scss';

type MenuHeaderProps = {
  title?: string;
};

const MenuHeader: React.FC<MenuHeaderProps> = ({
  title,
  children,
  ...rest
}) => {
  const { name } = useSelector((state: RootState) => state.user);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const dispatch = useDispatch();
  const showMenu = () => {
    dispatch(showSlideOutMenu());
  };

  return (
    <IonHeader className="ion-no-border hk-menu-header" {...rest}>
      <IonToolbar color="grey">
        <IonButtons slot="start">
          <IonButton onClick={() => showMenu()}>
            <IonIcon color="light" icon={MenuIcon} />
          </IonButton>
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="end">
          {!!name && !!currentHome && (
            <Link to="/account">
              <IonButton>
                <IonIcon color="light" icon={UserIcon} />
              </IonButton>
            </Link>
          )}
        </IonButtons>
      </IonToolbar>
      {children}
    </IonHeader>
  );
};

export default MenuHeader;
