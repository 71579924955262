import React from 'react';
import { IonContent, IonPage, IonRow, IonSpinner } from '@ionic/react';
import Logo from '../../assets/icons/HK-logo.svg';
import './LoadingSpinner.scss';

export enum LoadingMode {
  Dots,
  Logo
}

interface LoadingSpinnerProps {
  mode?: LoadingMode;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ mode = 0 }) => {
  return (
    <IonPage>
      <IonContent
        fullscreen
        scroll-y="false"
        className={`hk-loading ion-padding ion-text-center ${
          mode === LoadingMode.Logo ? 'logo' : 'dots'
        }`}
      >
        {mode === LoadingMode.Dots ? (
          <IonRow className="vertical-align-center hk-loading-list-spinner">
            <IonSpinner name="dots" color="grey" />
          </IonRow>
        ) : null}
        {mode === LoadingMode.Logo ? (
          <div className="hk-loading-logo">
            <img src={Logo} alt="HomeKeep logo" />
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default LoadingSpinner;
