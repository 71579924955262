import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HKDocumentType } from './DocumentDetailSlice';

export interface DocumentCount {
  count: 7;
  type: HKDocumentType;
  group: string;
  label: string;
}

interface DocumentState {
  counts: DocumentCount[] | null;
  documents: Document[] | null;
  error: string | null;
}

export const initialState: DocumentState = {
  counts: null,
  documents: null,
  error: null
};

export const DocumentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    fetchDocumentCountSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.counts = items;
    },
    fetchDocumentCountError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchDocumentsSuccess: (state, action: PayloadAction<any>) => {
      state.documents = action.payload;
    },
    fetchDocumentsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    resetDocuments: (state) => {
      state.documents = null;
    }
  }
});

export const {
  fetchDocumentCountSuccess,
  fetchDocumentCountError,
  fetchDocumentsSuccess,
  fetchDocumentsError,
  resetDocuments
} = DocumentSlice.actions;

export default DocumentSlice.reducer;
