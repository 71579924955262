import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect, useHistory } from 'react-router';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FirebaseAuthentication } from '@tambroseavid/capacitor-firebase-authentication';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX, firebaseConfig } from '../../core/constants';
import LeftArrow from '../../assets/icons/arrow-left.svg';
import { setLoading } from '../../core/services/LoadingSlice';
import './PasswordReset.scss';

const PasswordReset: React.FC = () => {
  const { accessToken, loading, error } = useSelector(
    (state: RootState) => state.login
  );
  const [passwordEmailSent, setPasswordEmailSent] = useState(false);
  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const history = useHistory();
  const dispatch = useDispatch();

  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);

  type FormData = {
    email: string;
  };

  const backToLogin = () => {
    history.push('/login');
  };

  const onFormSubmit = handleSubmit(({ email }) => {
    if (errors.email === undefined) {
      sendPasswordResetEmail(email).then();
    }
  });

  const sendPasswordResetEmail = async (email: string) => {
    dispatch(setLoading(true));
    await FirebaseAuthentication.sendPasswordResetEmail({ email: email })
      .then((result) => {
        if (result.success) {
          dispatch(setLoading(false));
          setPasswordEmailSent(true);
        }
      })
      .catch((error) => {
        console.log(`sendPasswordResetEmail error: ${error}`);
        dispatch(setLoading(false));
      });
  };

  return accessToken ? (
    <Redirect to="/checkpoint" />
  ) : (
    <IonPage>
      <IonContent className="reset-view ion-padding" fullscreen scrollY={true}>
        {!passwordEmailSent && (
          <section className="reset-view-header">
            <h3 className="ion-text-center">
              <b>Forgot your password?</b>
            </h3>
            <h6 className="reset-view-subheader ion-padding-horizontal ion-text-center">
              Enter the email associated with your account and we will send an
              email to reset your password.
            </h6>
          </section>
        )}
        {passwordEmailSent && (
          <section className="reset-view-header">
            <h3 className="ion-text-center">
              <b>Check your inbox!</b>
            </h3>
            <h6 className="reset-view-subheader ion-padding-horizontal ion-text-center">
              An email with instructions has been sent. Once your password is
              reset you may{' '}
              <IonRouterLink routerLink="login">Sign In</IonRouterLink>.
            </h6>
          </section>
        )}
        <section className="reset-view-email-form ion-padding-vertical ion-margin-vertical">
          <form className="hk-form" onSubmit={onFormSubmit}>
            <div className="hk-form-row">
              <input
                type="text"
                placeholder="Email"
                name="email"
                className="login-field"
                autoCorrect="off"
                autoCapitalize="none"
                color="medium"
                ref={register({
                  required: true,
                  minLength: 4,
                  pattern: EMAIL_REGEX
                })}
              />
            </div>
            <div className="hk-form-actions">
              <IonButton
                className="hk-auth-button ion-margin-vertical"
                color="primary"
                size="large"
                strong={true}
                type="submit"
              >
                Reset Password
              </IonButton>
            </div>
            {errors.email && (
              <p className="hk-error">Your email appears to be invalid.</p>
            )}
            {error && <p className="hk-error">{error}</p>}
          </form>
        </section>
        {loading && (
          <div className="hk-body-spinner">
            <IonRow className="vertical-align-center">
              <IonSpinner name="dots" />
            </IonRow>
          </div>
        )}
        <IonFab vertical="top" horizontal="start">
          <IonFabButton
            color="white"
            className="hk-fab-button ion-margin-top"
            onClick={backToLogin}
          >
            <IonIcon color="medium" icon={LeftArrow} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default PasswordReset;
