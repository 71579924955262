import { Worker } from '../../../core/types';
import React from 'react';
import { IonButton, IonIcon, IonText, IonThumbnail } from '@ionic/react';
import Clock from '../../../assets/icons/clock.svg';
import Calendar from '../../../assets/icons/calendar.svg';
import Pending from '../../../assets/icons/pending.svg';
import CompletedCheckmark from '../../../assets/icons/completed-check.svg';
import StarRating from '../../star-rating/StarRating';
import './ServicePersonCard.scss';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { HKPlatform } from 'core/constants';

type ServicePersonCardProps = {
  person?: Worker;
  time: string;
  date: string;
  completed: boolean;
  className?: string;
  rescheduleClicked?: (e: any) => void;
};

const ServicePersonCard: React.FC<ServicePersonCardProps> = ({
  person,
  time,
  date,
  completed = false,
  className = '',
  rescheduleClicked,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const updatePersonRating = (rating: number) => {
    console.log(rating);
  };

  function DesktopServicePersonCardView() {
    return (
      <div className={`hk-desktop-person-card ${className}`} {...rest}>
        <IonThumbnail slot="end" className="hk-desktop-person-card-thumbnail">
          <img src={person?.image} alt="Service person" />
        </IonThumbnail>
        <div className="hk-desktop-person-card-details">
          <div className="hk-desktop-person-card-details-name">
            <IonText color="white" className="hk-bold">
              {person?.name}
            </IonText>
          </div>
          <div className="hk-desktop-person-card-details-date">
            <IonText className="hk-service-person-title" color="white hk-bold">
              Appointment Date
            </IonText>
            <IonText color="white">{date}</IonText>
          </div>
          <div className="hk-desktop-person-card-details-time">
            <IonText className="hk-service-person-title" color="white hk-bold">
              Appointment Time
            </IonText>
            <IonText color="white">{time}</IonText>
          </div>
          <div className="hk-desktop-person-card-details-status">
            <IonText className="hk-service-person-title" color="white hk-bold">
              Status
            </IonText>
            {completed ? (
              <div className="hk-status-pill complete">complete</div>
            ) : (
              <div className="hk-status-pill incomplete">incomplete</div>
            )}
          </div>
        </div>
        <div className="hk-desktop-person-card-footer">
          {!completed && (
            <>
              <IonButton
                size="small"
                color="primary"
                className="hk-desktop-schedule-button"
                onClick={rescheduleClicked}
              >
                Reschedule
              </IonButton>
            </>
          )}
        </div>
      </div>
    );
  }

  function MobileServicePersonCardView() {
    return (
      <div className={`hk-person-card ${className}`} {...rest}>
        <div className="hk-person-card-header">
          <IonThumbnail slot="end" className="hk-person-card-header-thumbnail">
            <img src={person?.image} alt="Service person" />
          </IonThumbnail>
          <div className="hk-person-card-header-details">
            <div>
              <IonText color="white" className="hk-bold">
                {person?.name}
              </IonText>
            </div>
            <div>
              <IonIcon color="white" icon={Clock} />
              <IonText color="white">{time}</IonText>
            </div>
            <div>
              <IonIcon color="white" icon={Calendar} />
              <IonText color="white">{date}</IonText>
            </div>
          </div>
        </div>
        {/* {completed && (
          <div className="hk-person-card-rating">
            <IonText color="white">
              <p>
                Please rate your service specialist based on your experience.
              </p>
            </IonText>
            <StarRating onRatingChange={(e) => updatePersonRating(e)} />
          </div>
        )} */}
        <div className="hk-person-card-footer">
          {completed && <div></div>}
          {completed && (
            <div className="hk-person-card-footer-completed">
              <IonIcon color="primary" icon={CompletedCheckmark} />
              <IonText color="white">Complete</IonText>
            </div>
          )}
          {!completed && (
            <>
              <IonButton
                size="small"
                color="primary"
                className="hk-schedule-button"
                onClick={rescheduleClicked}
              >
                Reschedule
              </IonButton>
              <div className="hk-person-card-footer-pending">
                <IonIcon color="light" icon={Pending} />
                <IonText color="white">Pending</IonText>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopServicePersonCardView()
        : MobileServicePersonCardView()}
    </>
  );
};

export default ServicePersonCard;
