import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonText
} from '@ionic/react';
import { fetchProductDetails } from './InventoryActions';
import { gaScreenView, viewDocument } from '../../core/util';
import { HKDocumentType } from '../documents/DocumentDetailSlice';
import LoadingSpinner from '../../components/loading/LoadingSpinner';
import Image from '../../assets/icons/image.svg';
import './InventoryDetailsDesktop.scss';
import { Asset } from 'core/types';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';
import { HKPlatform } from 'core/constants';
import { useHistory } from 'react-router';

interface ItemAttribute {
  label: string;
  value: string;
}

const InventoryDetailsDesktop: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { product, product_id, loading } = useSelector(
    (state: RootState) => state.inventoryDetails
  );
  const [attributes, setAttributes] = useState<ItemAttribute[]>([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const assets = !!product
    ? [...product.product_template.assets, ...product.assets]
    : [];

  const imageSlideOpts = {
    initialSlide: 0,
    height: 400,
    speed: 400
  };

  const displayAssetThumbnail = (asset: Asset) => {
    return asset.thumb_cloud_store ? asset.thumb_cloud_store : asset.file_url;
  };

  useEffect(() => {
    if (!!product) {
      setAttributes([
        { label: 'Product', value: product.product_template.type },
        { label: 'Brand', value: product.product_template.manufacturer.name },
        { label: 'Model', value: product.product_template.model },
        { label: 'Serial', value: product.serial }
      ]);
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (!product && !!homeId && loading) {
      dispatch(fetchProductDetails(homeId, Number(product_id)));
      gaScreenView('/inventory/details');
    }
  }, [dispatch, product, loading, homeId, product_id]);

  useEffect(() => {
    const goBackToInventoryList = () => {
      history.goBack();
    };
    if (platformType === HKPlatform.DESKTOP && !isDesktopWidth) {
      goBackToInventoryList();
    }
  });

  return (
    <IonPage>
      <DesktopNavigation>
        <IonRow className="hk-desktop-inventory-details-breadcrumbs">
          {/* BREADCRUMBS HERE */}
        </IonRow>
      </DesktopNavigation>
      <IonContent>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <IonList className="hk-desktop-inventory-details">
            <div className="hk-desktop-inventory-details-overview">
              {assets.length ? (
                <IonSlides
                  className="hk-desktop-inventory-details-overview-image-slider"
                  pager={true}
                  options={imageSlideOpts}
                >
                  {assets.map((asset, i) => {
                    return (
                      <IonSlide key={`slide-${i}`}>
                        <img
                          src={displayAssetThumbnail(asset)}
                          alt="Product photo"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              ) : (
                <div className="hk-image-placeholder">
                  <img src={Image} alt="No image" />
                </div>
              )}
              <div className="hk-desktop-inventory-details-overview-container">
                <div className="hk-desktop-inventory-details-overview-product-details-section-header ion-no-padding">
                  <IonText className="hk-desktop-inventory-details-label">
                    Product Details
                  </IonText>
                </div>
                {attributes.map((att, i) => {
                  const { label, value } = att;
                  return (
                    <IonItem
                      key={`feature-${i}`}
                      lines="none"
                      className="hk-desktop-inventory-details-overview-attribute ion-no-padding"
                    >
                      <IonLabel color="medium">{label}</IonLabel>
                      <IonLabel className="hk-bold ion-text-end ion-no-margin">
                        {value}
                      </IonLabel>
                    </IonItem>
                  );
                })}
                <div className="hk-desktop-inventory-details-overview-section-header section-header-documents ion-no-padding">
                  <IonText className="hk-desktop-inventory-details-label">
                    Documents
                  </IonText>
                </div>
                <IonItem lines="none" className="ion-no-padding">
                  <div className="hk-desktop-inventory-details-overview-document-slider">
                    {product?.product_template.assets
                      .filter((asst) => asst.type === HKDocumentType.Manuals)
                      .map((attr, i) => {
                        const { name, file_url } = attr;
                        return (
                          <div
                            className="hk-desktop-inventory-details-overview-document-slider-slide"
                            key={`slide-${i}`}
                            onClick={() => viewDocument(file_url, platformType)}
                          >
                            <IonImg
                              src={product.product_template.manufacturer.image}
                              className="hk-desktop-inventory-details-overview-document-slider-slide-img"
                            />
                            <IonText
                              color="medium"
                              className="hk-desktop-inventory-details-overview-document-slider-slide-name ion-text-center"
                            >
                              {name}
                            </IonText>
                          </div>
                        );
                      })}
                  </div>
                </IonItem>
              </div>
            </div>
            <div className="hk-desktop-inventory-details-overview-section-header section-header-maintenance">
              <IonText className="hk-desktop-inventory-details-label">
                Maintenance
              </IonText>
            </div>
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default InventoryDetailsDesktop;
