import React, { useEffect, useState } from 'react';
import { RootState } from '../../core/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonText,
  IonToolbar
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import {
  resetUserEdit,
  resetUserInfo,
  showUserEdit
} from 'core/services/UserSlice';
import { logout } from 'features/login/LoginSlice';
import BackButtonHeader from '../../core/layout/BackButtonHeader';
import EditAccount from './EditAccount';
import EditIcon from '../../assets/icons/edit.svg';
import './Account.scss';
import { useHistory } from 'react-router-dom';
import { HKPlatform } from 'core/constants';
import DesktopNavigation from 'core/layout/desktop-navigation/DesktopNavigation';

export enum ModalMode {
  Hidden,
  User,
  Home
}

interface Section {
  label: string;
  values: string[];
  action?: () => void;
}

export interface InputField {
  label: string;
  id: string;
  type: number;
}

export const userFields: InputField[] = [
  { label: 'Name', id: 'name', type: ModalMode.User },
  { label: 'Email', id: 'email', type: ModalMode.User },
  { label: 'Mobile', id: 'mobile_phone', type: ModalMode.User }
];

export const homeFields: InputField[] = [
  { label: 'Address', id: 'address1', type: ModalMode.Home },
  { label: 'City', id: 'city', type: ModalMode.Home },
  { label: 'State', id: 'state', type: ModalMode.Home },
  { label: 'Zip Code', id: 'postal_code', type: ModalMode.Home }
];

export const allFields = [...userFields, ...homeFields];

const Account: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { name, modal_mode } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [sections, setSections] = useState<Section[]>([]);
  const dispatch = useDispatch();
  let history = useHistory();

  const logoutUser = () => {
    firebase.auth().signOut().then();
    dispatch(logout());
    dispatch(resetUserInfo());
    history.push(`/`);
  };

  const editUser = () => {
    dispatch(showUserEdit(ModalMode.User));
  };

  const editHome = () => {
    dispatch(showUserEdit(ModalMode.Home));
  };

  useEffect(() => {
    if (!!currentHome) {
      setSections([
        {
          label: 'Contact Info',
          values: [user.email, user.mobile_phone],
          action: editUser
        },
        {
          label: 'Address',
          values: [
            currentHome.address1,
            currentHome.address2,
            `${currentHome.city}, ${currentHome.state} ${currentHome.postal_code}`
          ],
          action: editHome
        },
        {
          label: 'Subscriptions',
          values: currentHome.subscriptions.map((sub) => sub.name)
        }
      ]);
    }
  }, [user, currentHome]);

  function DesktopAccountView() {
    return (
      <>
        <DesktopNavigation></DesktopNavigation>
        <IonContent>
          <div className="hk-desktop-account">
            <div className="hk-desktop-account-header">
              {name.split(' ').map((fragment, i) => {
                return <IonText key={`text${i}`}>{fragment}</IonText>;
              })}
            </div>
            <IonList className="">
              {sections.map((field, i) => {
                const { label, values, action } = field;
                return (
                  <IonItem
                    lines="none"
                    color="dark"
                    key={`item-${i}`}
                    className={`hk-desktop-account-item ${
                      !!action ? 'editable' : ''
                    }`}
                    onClick={action}
                  >
                    <div className="hk-desktop-account-container">
                      <div className="hk-desktop-account-details">
                        <IonLabel className="hk-desktop-account-label">
                          <IonText>{label}</IonText>
                        </IonLabel>
                        <IonLabel>
                          {values.map((val, j) => {
                            return !!val ? (
                              <IonText
                                key={`val-${i}-${j}`}
                                className="hk-desktop-account-value"
                              >
                                {label === 'Contact Info' && j > 0 && ', '}
                                {val}
                                {label === 'Address' && j === 0 && ', '}
                              </IonText>
                            ) : null;
                          })}
                        </IonLabel>
                      </div>
                      {!!action && (
                        <IonIcon
                          className="hk-desktop-account-edit-icon"
                          icon={EditIcon}
                          color="primary"
                        />
                      )}
                    </div>
                  </IonItem>
                );
              })}
            </IonList>
            <div className="hk-desktop-account-footer">
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                className=""
                onClick={logoutUser}
              >
                Log Out
              </IonButton>
            </div>
          </div>
        </IonContent>
      </>
    );
  }

  function MobileAccountView() {
    return (
      <>
        <BackButtonHeader title={'Account'} color={'dark'} />
        <IonContent className="hk-account">
          <div className="hk-account-header">
            {name.split(' ').map((fragment, i) => {
              return <IonText key={`text${i}`}>{fragment}</IonText>;
            })}
          </div>
          <IonList className="ion-margin-vertical">
            {sections.map((field, i) => {
              const { label, values, action } = field;
              return (
                <IonItem
                  lines="none"
                  color="dark"
                  key={`item-${i}`}
                  className="ion-margin-horizontal"
                >
                  <IonLabel className="hk-account-label ion-text-nowrap">
                    <IonText>{label}</IonText>
                  </IonLabel>
                  {values.map((val, j) => {
                    return !!val ? (
                      <IonLabel
                        key={`val-${i}-${j}`}
                        position="stacked"
                        className="hk-account-value ion-text-nowrap"
                      >
                        <IonText>{val}</IonText>
                      </IonLabel>
                    ) : null;
                  })}
                  {!!action && (
                    <IonIcon
                      slot="end"
                      className="hk-account-edit-icon"
                      icon={EditIcon}
                      color="primary"
                      onClick={action}
                    />
                  )}
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
        <IonToolbar
          color="dark"
          className="hk-account-toolbar ion-padding-bottom ion-padding-start ion-padding-end"
        >
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            className="ion-margin"
            onClick={logoutUser}
          >
            Log Out
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopAccountView()
        : MobileAccountView()}
      <IonModal
        cssClass="hk-desktop-account-edit-modal"
        isOpen={modal_mode > ModalMode.Hidden}
        onDidDismiss={() => dispatch(resetUserEdit())}
      >
        <EditAccount />
      </IonModal>
    </IonPage>
  );
};

export default Account;
