import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Appointment } from '../../core/types';

export interface Visit {
  can_schedule: boolean;
  duration: number;
  description: string;
  date: string;
  home_id: number;
  name: string;
  plan_hash: string;
  season: string;
  type: string;
}

interface DashboardState {
  loading: boolean;
  error: string | null;
  appointments: Appointment[] | null;
  upcomingVisits: Visit[] | null;
}

export const initialState: DashboardState = {
  loading: false,
  error: null,
  appointments: null,
  upcomingVisits: null
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchAppointmentListStart: (state) => {
      state.loading = true;
    },
    fetchAppointmentListSuccess: (
      state,
      action: PayloadAction<Appointment[]>
    ) => {
      state.appointments = action.payload;
      state.loading = false;
    },
    fetchAppointmentListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetAppointmentList: (state) => {
      state.appointments = null;
      state.loading = false;
      state.error = null;
    },
    fetchVisitsListStart: (state) => {
      state.loading = true;
    },
    fetchVisitsListSuccess: (state, action: PayloadAction<Visit[]>) => {
      state.upcomingVisits = action.payload;
      state.loading = false;
    },
    fetchVisitsListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetVisitsList: (state) => {
      state.error = null;
      state.upcomingVisits = null;
    }
  }
});

export const {
  fetchAppointmentListStart,
  fetchAppointmentListSuccess,
  fetchAppointmentListError,
  resetAppointmentList,
  fetchVisitsListStart,
  fetchVisitsListSuccess,
  fetchVisitsListError,
  resetVisitsList
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
