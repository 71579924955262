import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToolbar
} from '@ionic/react';
import { userFields, homeFields, ModalMode, InputField } from './Account';
import { updateUser } from 'core/services/UserActions';
import { Controller, useForm } from 'react-hook-form';
import {
  EMAIL_REGEX,
  HKPlatform,
  PHONE_REGEX,
  STATE_LIST
} from '../../core/constants';
import { resetUserEdit } from 'core/services/UserSlice';
import EditIcon from '../../assets/icons/edit.svg';
import { updateHome } from 'core/services/HomeActions';
import { Home } from '../../core/services/HomeSlice';
import { gaScreenView } from '../../core/util';
import Close from '../../assets/icons/close.svg';
import './EditAccount.scss';

export interface AccountForm {
  [index: string]: string;
}

const EditAccount: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const { name, email, mobile_phone, modal_mode } = useSelector(
    (state: RootState) => state.user
  );
  const homes = useSelector((state: RootState) => state.home.homes);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [currentHome, setCurrentHome] = useState<Home | null>();
  const [fields, setFields] = useState<InputField[]>([]);
  const dispatch = useDispatch();

  const stateAlertOptions = {
    cssClass: 'hk-state-alert',
    header: 'Select State',
    translucent: true
  };

  const onSubmit = (values: any) => {
    if (modal_mode === ModalMode.User) {
      dispatch(updateUser(values));
    } else {
      if (!!currentHome) {
        dispatch(updateHome(values, currentHome.id));
      }
    }
  };

  const cancelEdit = () => {
    dispatch(resetUserEdit());
  };

  const formRule = (id: string) => {
    if (id === 'email') {
      return {
        required: true,
        pattern: {
          value: EMAIL_REGEX,
          message: 'Invalid email'
        }
      };
    }
    if (id === 'mobile_phone') {
      return {
        required: true,
        pattern: {
          value: PHONE_REGEX,
          message: 'Invalid phone number'
        }
      };
    }
    return { required: true };
  };

  const { control, handleSubmit, errors, formState, reset } =
    useForm<AccountForm>({
      defaultValues: {
        name: '',
        email: '',
        mobile_phone: '',
        address1: '',
        city: '',
        state: '',
        postal_code: ''
      },
      mode: 'onChange'
    });

  useEffect(() => {
    if (!!homes) {
      setCurrentHome(homes[0]);
    }
  }, [homes]);

  useEffect(() => {
    if (!!currentHome) {
      reset({
        name,
        email,
        mobile_phone,
        address1: currentHome.address1,
        city: currentHome.city,
        state: currentHome.state,
        postal_code: currentHome.postal_code
      });
    }
  }, [currentHome, fields]);

  useEffect(() => {
    setFields(modal_mode === ModalMode.User ? userFields : homeFields);
    if (modal_mode !== ModalMode.Hidden) {
      gaScreenView(
        modal_mode === ModalMode.User
          ? '/account/edit/contact'
          : '/account/edit/home'
      );
    }
  }, [modal_mode]);

  function DesktopEditAccountView() {
    return (
      <>
        <IonContent className="ion-padding">
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList className="hk-desktop-account-edit ion-margin">
              <IonListHeader className="ion-no-padding ion-margin-vertical">
                <IonLabel
                  color="dark"
                  className="hk-desktop-account-edit-title"
                >
                  <IonIcon color="dark" icon={EditIcon} />
                  Edit{' '}
                  {modal_mode === ModalMode.User ? 'contact info' : 'address'}
                </IonLabel>
              </IonListHeader>
              <div className="hk-desktop-account-edit-form">
                {fields.map((field, i) => {
                  const { id, label } = field;
                  return (
                    <Controller
                      key={`controller-${i}`}
                      control={control}
                      render={({ onChange, onBlur, value }) => (
                        <IonItem
                          lines="none"
                          className="hk-desktop-account-edit-form-item ion-no-padding"
                        >
                          <IonLabel position="stacked" color="medium">
                            {label}
                          </IonLabel>
                          {id !== 'state' && (
                            <IonInput
                              class="hk-desktop-account-edit-field ion-margin-bottom"
                              onIonChange={(value) => {
                                onChange(value);
                              }}
                              value={value}
                              color={errors[field.id] ? 'danger' : 'dark'}
                              placeholder={label}
                              type="text"
                            ></IonInput>
                          )}
                          {id === 'state' && (
                            <IonSelect
                              className="hk-state-select"
                              placeholder="Select state"
                              value={value}
                              interfaceOptions={stateAlertOptions}
                              color={errors[field.id] ? 'danger' : 'light'}
                              onIonChange={(e) => onChange(e.detail.value)}
                            >
                              {STATE_LIST.map((state) => {
                                return (
                                  <IonSelectOption
                                    key={state.abbreviation}
                                    value={state.abbreviation}
                                  >
                                    {state.name}
                                  </IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          )}
                        </IonItem>
                      )}
                      name={id}
                      rules={formRule(id)}
                    />
                  );
                })}
              </div>
            </IonList>
          </form>
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'ion-margin-top'
              }`}
              onClick={cancelEdit}
            >
              <IonIcon color="medium" icon={Close} />
            </IonFabButton>
          </IonFab>
        </IonContent>
        <div className="hk-desktop-account-edit-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            disabled={loading}
            onClick={cancelEdit}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={!formState.isValid || loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <IonSpinner name="dots" /> : 'Update'}
          </IonButton>
        </div>
      </>
    );
  }

  function MobileEditAccountView() {
    return (
      <>
        <IonContent fullscreen className="ion-padding">
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList className="hk-account-edit ion-margin">
              <IonListHeader className="ion-no-padding ion-text-center ion-margin-vertical">
                <IonLabel color="dark" className="hk-account-edit-title">
                  <IonIcon color="dark" icon={EditIcon} />
                  Edit{' '}
                  {modal_mode === ModalMode.User ? 'contact info' : 'address'}
                </IonLabel>
              </IonListHeader>
              {fields.map((field, i) => {
                const { id, label } = field;
                return (
                  <Controller
                    key={`controller-${i}`}
                    control={control}
                    render={({ onChange, onBlur, value }) => (
                      <IonItem lines="none" className="ion-no-padding">
                        <IonLabel position="stacked" color="medium">
                          {label}
                        </IonLabel>
                        {id !== 'state' && (
                          <IonInput
                            class="hk-account-edit-field ion-margin-bottom"
                            onIonChange={(value) => {
                              onChange(value);
                            }}
                            value={value}
                            color={errors[field.id] ? 'danger' : 'dark'}
                            placeholder={label}
                            type="text"
                          ></IonInput>
                        )}
                        {id === 'state' && (
                          <IonSelect
                            className="hk-state-select"
                            placeholder="Select state"
                            value={value}
                            interfaceOptions={stateAlertOptions}
                            color={errors[field.id] ? 'danger' : 'light'}
                            onIonChange={(e) => onChange(e.detail.value)}
                          >
                            {STATE_LIST.map((state) => {
                              return (
                                <IonSelectOption
                                  key={state.abbreviation}
                                  value={state.abbreviation}
                                >
                                  {state.name}
                                </IonSelectOption>
                              );
                            })}
                          </IonSelect>
                        )}
                      </IonItem>
                    )}
                    name={id}
                    rules={formRule(id)}
                  />
                );
              })}
            </IonList>
          </form>
        </IonContent>
        <IonToolbar className="ion-padding">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={cancelEdit}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={!formState.isValid || loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <IonSpinner name="dots" /> : 'Update'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopEditAccountView()
        : MobileEditAccountView()}
    </IonPage>
  );
};

export default EditAccount;
