import React from 'react';
import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import './ListItem.scss';

type ListItemProps = {
  title?: string;
  description?: string;
  phone?: string;
  startLabel?: string;
  startIcon?: string;
  endIcon?: string;
  href?: string;
  altView?: boolean;
  customView?: boolean;
  onClick?: (e: any) => void;
};

const ListItem: React.FC<ListItemProps> = ({
  title,
  description,
  phone,
  startIcon,
  startLabel,
  endIcon,
  href,
  altView,
  customView,
  onClick,
  children,
  ...rest
}) => {
  function CustomListItemView() {
    return (
      <IonItem
        lines="none"
        className="hk-alt-list-item"
        href={href}
        onClick={onClick}
        {...rest}
      >
        {children}
      </IonItem>
    );
  }

  function AlternativeListItemView() {
    return (
      <IonItem
        lines="none"
        className="hk-alt-list-item"
        href={href}
        onClick={onClick}
        {...rest}
      >
        {startIcon && (
          <IonIcon
            slot="start"
            icon={startIcon}
            className="hk-alt-list-item-start-icon"
          />
        )}
        {startLabel && (
          <IonText slot="start" className="hk-alt-list-item-start-label">
            {startLabel}
          </IonText>
        )}
        <div className="hk-alt-list-item-label">
          <IonText className="hk-alt-list-item-title">
            <h2>{title}</h2>
          </IonText>
          <IonText className="hk-alt-list-item-description">
            <div>Contact Type</div>
            <p>{description}</p>
          </IonText>
          <div className="hk-alt-list-item-phone">
            <IonIcon
              slot="end"
              icon={endIcon ? endIcon : chevronForwardOutline}
              className="hk-alt-list-item-end-icon"
            ></IonIcon>
            <IonText className="hk-alt-list-item-phone-number">
              <div>Phone</div>
              {!!phone ? <p>{phone}</p> : <p>No phone number</p>}
            </IonText>
          </div>
        </div>
      </IonItem>
    );
  }

  function MobileListItemView() {
    return (
      <IonItem
        lines="none"
        className="hk-list-item ion-margin-horizontal"
        href={href}
        onClick={onClick}
        {...rest}
      >
        {startIcon && (
          <IonIcon
            slot="start"
            icon={startIcon}
            className="hk-list-item-start-icon"
          />
        )}
        {startLabel && (
          <IonText slot="start" className="hk-list-item-start-label">
            {startLabel}
          </IonText>
        )}
        <IonLabel>
          <IonText color="dark">
            <h2 className="hk-list-item-title">{title}</h2>
          </IonText>
          <IonText color="medium">
            <p className="hk-list-item-description">{description}</p>
          </IonText>
        </IonLabel>
        <IonIcon
          slot="end"
          icon={endIcon ? endIcon : chevronForwardOutline}
          className="hk-list-item-end-icon"
        ></IonIcon>
      </IonItem>
    );
  }

  return (
    <>
      {altView === true && AlternativeListItemView()}
      {customView === true && CustomListItemView()}
      {!altView && !customView && MobileListItemView()}
    </>
  );
};

export default ListItem;
